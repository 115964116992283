import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	reference: {
		textAlign: 'center',
		maxWidth: '1000px'
	},
	newLine: {
		whiteSpace: 'pre-line'
	},
	button: {
		marginTop: '0.5rem',
		marginRight: '0.5rem'
	},
	buttonSelected: {
		marginTop: '0.5rem',
		marginRight: '0.5rem',
		backgroundColor: 'cadetblue'
	}
}));

export default function ReferenceContent(props) {
	const {switchFiltering, filterBy} = props;
	const classes = useStyles();
	
	const switchFilter = (e) => {
		switchFiltering(e.currentTarget.value);
	};
	return(
		<div className={classes.reference}>
			<Typography className={classes.newLine}>
			{'Listed here are some of the goats who have had an important impact on our herd. All of the goats shown here at one time called our farm home. Goats are displayed in age order, from oldest to youngest, feel free to use the button below to filter by bucks or does.'}
			</Typography>
			<Button variant="contained" className={filterBy === '' ? classes.buttonSelected : classes.button} size="large" onClick={switchFilter} value={''}>
				All Goats
			</Button>
			<Button variant="contained" className={filterBy === 'Buck' ? classes.buttonSelected : classes.button} size="large" onClick={switchFilter} value={'Buck'}>
				Bucks
			</Button>
			<Button variant="contained" className={filterBy === 'Doe' ? classes.buttonSelected : classes.button} size="large" onClick={switchFilter} value={'Doe'}>
				Does
			</Button>
		</div>
	);
};

ReferenceContent.propTypes = {
	switchFiltering: PropTypes.func.isRequired,
	filterBy: PropTypes.string.isRequired
};