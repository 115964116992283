import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
	width: '100%',
	left: '0',
	bottom: '0',
	textAlign: 'center',
	backgroundColor: 'white'
  }
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
		<Typography variant="caption">
			Copyright Imagination Acres 2009-2024
		</Typography>
    </div>
  );
}
