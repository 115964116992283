const GoatSalesList = {
	BuckKids2022: [
		{
			"Sire": "This & That Acres Mocha *B",
			"SireNickname": "Mocha",
			"Dam": "Imagination Acres Wonton 3*M",
			"DamNickname": "Wonton",
			"Kidded": "March 25, 2022",
			"DamReg": "D1792375",
			"SireReg": "D2068271",
			"Reference": false,
			"Name": "Egg Roll (Nickname)",
			"Tattoo": "P15",
			"Description": "Egg Roll is polled and blue eyed. He can go as an ADGA registered buck or unregistered.",
			"Price": "$150 Unregistered or $350 with ADGA Registration Application",
		},
		{
			"Sire": "TIL-RIV CT Spicey Solution *B",
			"SireNickname": "Spicey",
			"Dam": "Imagination Acres Rhubarb 2*M",
			"DamNickname": "Rhubarb",
			"Kidded": "March 25, 2022",
			"DamReg": "D1792375",
			"SireReg": "D2068271",
			"Born": true,
			"Reference": false,
			"DamReference": true,
			"Name": "Tobasco (Nickname)",
			"Tattoo": "P17",
			"Frozen": true,
			"Description": "Tobasco is disbudded and blue eyed. He can go as an ADGA registered buck or unregistered.",
			"Price": "$150 Unregistered or $350 with ADGA Registration Application",

		},
		{
			"SireNickname": "Mocha",
			"Sire": "This & That Acres Mocha *B",
			"Dam": "Imagination Acres Doublestuff 1*M 88 VVEV",
			"DamNickname": "Doublestuff",
			"Kidded": "March 31, 2022",
			"SireReg": "D2068271",
			"DamReg": "D1880839",
			"Reference": false,
			"Name": "Jaguar (Nickname)",
			"Tattoo": "P19",
			"Description": "Jaguar is polled and brown eyed. He can go as an ADGA registered buck or unregistered.",
			"Price": "$150 Unregistered or $350 with ADGA Registration Application",
		}
	],
	DoeKids2022: [
		{
			"Sire": "This & That Acres Mocha *B",
			"SireNickname": "Mocha",
			"Dam": "Imagination Acres Daiquiri 1*M 88 VVEV",
			"DamNickname": "Daiq",
			"Kidded": "March 6, 2022",
			"SireReg": "D2068271",
			"DamReg": "D1880837",
			"Reference": false,
			"Name": "Imagination Acres As You Wish (Buttercup)",
			"Tattoo": "P4",
			"Description": "Buttercup is disbudded and brown eyed. She is already ADGA registered.",
			"Price": "$400 ADGA Registered with her papers in hand",
		},
		{
			"Sire": "This & That Acres Mocha *B",
			"SireNickname": "Mocha",
			"Dam": "Imagination Acres Rain Cloud 86 V+VV",
			"DamNickname": "Cloud",
			"Kidded": "March 7, 2022",
			"DamReg": "D1721603",
			"SireReg": "D2068271",
			"DoeReference": true,
			"Name": "Imagination Acres Mini Mouse",
			"Tattoo": "P5",
			"Description": "Mini is disbudded and brown eyed. She is already ADGA registered.",
			"Price": "$400 ADGA Registered with her papers in hand",
		},
		{
			"Sire": "This & That Acres Mocha *B",
			"SireNickname": "Mocha",
			"Dam": "Imagination Acres Rain Cloud 86 V+VV",
			"DamNickname": "Cloud",
			"Kidded": "March 7, 2022",
			"DamReg": "D1721603",
			"SireReg": "D2068271",
			"DoeReference": true,
			"Name": "Imagination Acres Ima Monsta",
			"Tattoo": "P6",
			"Description": "Monsta is disbudded and brown eyed. She is already ADGA registered.",
			"Price": "$400 ADGA Registered with her papers in hand"
		},
	],
	Bucks: [],
	Does: [   
		/*{    
			"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2156802",    
			"DATE_OF_BIRTH": "03/09/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES CLEO",       
			"TATTOO": "RE: IMG LE: N2 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS DARK CORNER FARM MOON SHADOW 86 VVV",
			"DAM": "IMAGINATION ACRES ACRES DOUBLESTUFF *M 88 VVEV",
			"DOB": "2021-03-09",
			"NICKNAME": "Cleo",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
			"DamReference": false,
			"DamNickname": "Doublestuff",
			"SireReference": true,
			"SireNickname": "Eclipse"
		},*/
		{
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2242330",    
			"DATE_OF_BIRTH": "05/29/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES TWIX AI",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "KYEEMA RIDGE LOVESTRUCK ROMEO",
			"DAM": "IMAGINATION ACRES ACRES TWIST IT UP 4*M 86 VVE+",
			"DOB": "2021-05-29",
			"NICKNAME": "Twix",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
			"DamReference": true,
			"DamNickname": "Pretzel",
			"SireFrozenReference": true,
			"SireNickname": "Romeo"
		},
		{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2218565",    
			"DATE_OF_BIRTH": "03/6/2022",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES VANTABLACK",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "THIS AND THAT ACRES MOCHA *B",
			"DAM": "IMAGINATION ACRES DAIQUIRI *M 88 VVEV",
			"DOB": "2022-03-06",
			"NICKNAME": "Vanta",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
			"DamReference": true,
			"DamNickname": "Daiq",
			"SireReference": true,
			"SireNickname": "Mocha"
		}/*,
		{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2223334",    
			"DATE_OF_BIRTH": "03/24/2022",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES JUST 2 MUCH",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "THIS AND THAT ACRES MOCHA *B",
			"DAM": "KYEEMA RIDGE MT JUST TOO SWEET *M 86 VEV+",
			"DOB": "2022-03-24",
			"NICKNAME": "Extra",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
			"DamReference": true,
			"DamNickname": "Dulce",
			"SireReference": true,
			"SireNickname": "Mocha"
		}*/	
	]
}

export default GoatSalesList;
