import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import PropTypes from 'prop-types';
import GoatPhotoArrays from './Images/GoatPhotoArrays.js';
import './gallery.css';
import Typography from '@material-ui/core/Typography';

export default function GoatGallery(props) {
	const {nickName} = props;
    return (GoatPhotoArrays[nickName] ? 
		<div>
			<Typography variant="h6">{`Photos of ${nickName}`}</Typography>
			<ImageGallery showFullscreenButton={false} items={GoatPhotoArrays[nickName]} />
		</div> : <div></div>);
};

GoatGallery.propTypes = {
	nickName: PropTypes.string.isRequired
};