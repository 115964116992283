const GoatList = { 
	Goats: [  
		{    
			"ID": 1260888,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1469267",    
			"DATE_OF_BIRTH": "03/28/2008",    
			"STARS": "     ",    
			"GOAT_NAME": "AGS COUNTRY BUMPKINS CAD CATAPULT",    
			"GOAT_PEDIGREE": "AGS COUNTRY BUMPKINS CAD CATAPULT     <br />D1469267                 PTI -65 -100<br /> 07-01 84 ++V+<br />PTA          -15M   -1F   -1P  -.50T<br />DEV          -60    -3    -4<br />PTA$                -2    -3     35R<br />PTA%  34R 08/20    0  -2  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     7-00  280   280  7.5  21  4.3  12   80  40<br />  LIFE       280   280  7.5  21  4.3  12<br />",    
			"TATTOO": "RE: OCB LE: Y47 ",    
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": true,	
			"LA": "07-01 84 ++V+",
			"SIRE": "GCH AGS WOODHAVEN FARMS COWBOY CADILAK ++B",
			"DAM": "AGS DILL'S AC CATILLAC",
			"NICKNAME": "Caddy",
			"DOB": "2008-03-28",
			"SEX": "Doe",
			"DNA": ""
		},  
		{    
			"ID": 1260889,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1469268",    
			"DATE_OF_BIRTH": "03/28/2008",    
			"STARS": "1*M  ",    
			"GOAT_NAME": "AGS COUNTRY BUMPKINS WN WINN-DIXIE",    
			"GOAT_PEDIGREE": "AGS COUNTRY BUMPKINS WN WINN-DIXIE    <br />D1469268       1*M       PTI 10 -8<br /> 07-01 87 VVEV<br />PTA            1M    1F    0P  -.10T<br />DEV            4     3     1<br />PTA$                 1     2     35R<br />PTA%  41R 08/20    5   2  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     7-00  302   330  7.9  26  4.8  16   80  40<br />  LIFE       302   330  7.9  26  4.8  16<br />",    
			"TATTOO": "RE: OCB LE: Y50 ",    
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": true,
			"LA": "07-01 87 VVEV",
			"SIRE": "AGS KAAPIO ACRES KK WING NUT",
			"DAM": "AGS COUNTRY BUMPKINS WHISTLN DIXIE",
			"NICKNAME": "Dixie",
			"DOB": "2008-03-28",
			"SEX": "Doe",
			"DNA": ""
		},  
		{    
			"ID": 1361236,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1569419",    
			"DATE_OF_BIRTH": "06/09/2011",    
			"STARS": "2*M  ",    
			"GOAT_NAME": "SG IMAGINATION ACRES ALM DUDETTE",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES ALM DUDETTE         <br />D1569419       2*M    SG PTI 30 28<br /> 06-01 85 ++VV<br />PTA            1M    1F    0P   .10T<br />DEV           33     4     3<br />PTA$                 1     2     43R<br />PTA%  49R 08/20    5   3  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     3-10  302   450  6.9  31  4.7  21   80  40<br />     4-10  294   580  7.2  42  4.3  25   80  40<br />     5-10  305   540  6.3  34  4.4  24   91  40<br />           331   570  6.5  37  4.6  26   91  40<br />  LIFE       927  1600  6.9 110  4.5  72<br />",    
			"TATTOO": "RE: IMG LE: B2 ",    
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": true,
			"LA": "06-01 85 ++VV",
			"SIRE": "AGS CORNERSTONE FARM ALMON *S",
			"DAM": "AGS COUNTRY BUMPKINS WN WINN-DIXIE 1*M 87 VVEV",
			"NICKNAME": "Dudette",
			"DOB": "2011-06-09",
			"SEX": "Doe",
			"DNA": ""
		},  
		{    
			"ID": 1380134,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1588302",    
			"DATE_OF_BIRTH": "03/19/2012",    
			"STARS": "+B   ",    
			"GOAT_NAME": "END OF THE LINE CUPID",    
			"GOAT_PEDIGREE": "END OF THE LINE CUPID                **DNA**<br />D1588302       +B        <br /> 05-04 88 VVE<br />PTA                            -.70T<br />PTA$                      31R<br />PTA%                          11/19<br />D/AV                             82<br />",    
			"TATTOO": "RE: EOTL LE: C14 ",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "05-04 88 VVE",
			"SIRE": "PHOENIX FARM KNIGHT RIDER +*B",
			"DAM": "AGS LITTLE SLEEPY H SWEET PEA ",
			"NICKNAME": "Cupid",
			"DOB": "2012-03-19",
			"DNA": "**DNA**",
			"RETIRED": true,
			"SEX": "Buck"
		},  
		{    
			"ID": 1456704,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1664674",    
			"DATE_OF_BIRTH": "04/10/2013",    
			"STARS": "1*M  ",    
			"GOAT_NAME": "KYEEMA RIDGE MT JUST 2 SWEET",    
			"GOAT_PEDIGREE": "KYEEMA RIDGE MT JUST 2 SWEET          <br />D1664674       1*M       PTI -55 -81<br /> 04-03 86 VEV+<br />PTA          -12M   -1F    0P  -.40T<br />DEV          -35    -1     1<br />PTA$                -2     0     50R<br />PTA%  51R 08/20   -1   3  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     2-10  355   500  7.6  38  5  25   80  40<br />     4-01  305   510  6.7  34  5.7  29   91  40<br />           323   520  6.7  35  5.8  30   91  40<br />  LIFE       678  1020  7.2  73  5.4  55<br />",    
			"TATTOO": "RE: KYR LE: D8 ",   
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": false,
			"LA": "04-03 86 VEV+",
			"SIRE": "BUTTIN'HEADS MARTINIQUE  +B 90 EEE",
			"DAM": "KYEEMA RIDGE HNC JUSTYN THYME 90 EEEV",
			"NICKNAME": "Dulce",
			"DOB": "2013-04-10",
			"DNA": "",
			"SEX": "Doe"
		},  
		{    
			"ID": 1472765,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1680710",    
			"DATE_OF_BIRTH": "05/07/2013",    
			"STARS": "3*M  ",    
			"GOAT_NAME": "IMAGINATION ACRES RAINSTORM",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES RAINSTORM           <br />D1680710       3*M       PTI -35 -71<br /> 02-00 81 ++++<br />PTA            2M    0F    1P  -.40T<br />DEV           12     1     3<br />PTA$                 0     1     38R<br />PTA%  43R 08/20    0   3  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     1-11  305   290  7.6  22  4.8  14   79  40<br />           316   300  7.3  22  5  15   79  40<br />     2-11  297   510  7.5  38  5.3  27   80  40<br />  LIFE       613   810  7.4  60  5.2  42<br />",    
			"TATTOO": "RE: IMG LE: D1 ",    
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": false,
			"LA": "02-00 81 ++++",
			"SIRE": "END OF THE LINE CUPID +B 88 VVE",
			"DAM": "SG IMAGINATION ACRES ALM DUDETTE 2*M 85 ++VV",
			"NICKNAME": "Rainstorm",
			"DOB": "2013-05-07",
			"SEX": "Doe",
			"DNA": ""
		},  
		{    
			"ID": 1513714,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1721602",    
			"DATE_OF_BIRTH": "03/14/2015",    
			"STARS": "2*M  ",    
			"GOAT_NAME": "IMAGINATION ACRES RHUBARB",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES RHUBARB             <br />D1721602       2*M       PTI -71 -116<br /> 02-04 83 +V++<br />PTA          -20M    0F   -1P  -.60T<br />DEV          -47    -1    -3<br />PTA$                -2    -3     34R<br />PTA%  40R 08/20    3  -2  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     1-02  281   360  8.1  29  4.4  16   79  40<br />     2-02  305   420  6  25  4  17   91  40<br />           318   430  6  26  4  17   91  40<br />  LIFE       599   790  7  55  4.2  33<br />",    
			"TATTOO": "RE: IMG LE: F4 ",    
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": false,
			"LA": "02-04 83 +V++",
			"SIRE": "AGS CORNERSTONE FARM ALMON *S",
			"DAM": "AGS CAPRICOPIA'S ZELDA 1*M  85 +VEV",
			"NICKNAME": "Rhubarb",
			"DOB": "2015-03-14",
			"FOR_SALE": false,
			"SEX": "Doe",
			"DNA": ""
			},  
		{    
			"ID": 1513715,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1721603",    
			"DATE_OF_BIRTH": "04/11/2015",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES RAIN CLOUD",    "GOAT_PEDIGREE": "IMAGINATION ACRES RAIN CLOUD          <br />D1721603                 PTI -8 -4<br /> 02-03 86 V+VV<br />PTA            0M    0F    0P   0T<br />DEV          -89    -6    -1<br />PTA$                 0     1     37R<br />PTA%  35R 08/20   -2   3  11/19<br />",    
			"TATTOO": "RE: IMG LE: F6 ",    
			"CUSTOMER_ID_MATCH": 0,
			"RETIRED": false,
			"LA": "02-03 86 V+VV",
			"SIRE": "SG PROCTOR HILL FARM EVAN WILLIAM 85 +EE +B",
			"DAM": "IMAGINATION ACRES RAINSTORM 3*M 81 ++++",
			"SHOW_WINS": ["Junior Grand Champion (dry leg) Best Junior Doe In Show 2015 Virginia State Fair (ADGA)"],
			"NICKNAME": "Cloud",
			"DOB": "2015-04-11",
			"DNA": "",
			"SEX": "Doe"
		},  
		{    
			"ID": 1513716,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1721604",    
			"DATE_OF_BIRTH": "04/12/2015",    
			"STARS": "2*M  ",    
			"GOAT_NAME": "IMAGINATION ACRES GODIVA",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES GODIVA              <br />D1721604       2*M       PTI -38 -46<br /> 02-03 86 +V+V<br />PTA            1M   -1F    0P  -.20T<br />DEV           11    -3    -2<br />PTA$                -1    -2     37R<br />PTA%  37R 08/20   -6  -2  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     2-00  305   420  5.2  22  4  17   88  40<br />           328   430  5.3  23  4.2  18   88  40<br />  LIFE       328   430  5.3  23  4.2  18<br />",    
			"TATTOO": "RE: IMG LE: F8 ",   
			"CUSTOMER_ID_MATCH": 0,
			"LA": "02-03 86 +V+V",
			"SIRE": "SG PROCTOR HILL FARM EVAN WILLIAM 85 +EE +B",
			"DAM": "IMAGINATION ACRES POWDER 1*M 85 VVVV",
			"NICKNAME": "Godiva",
			"DOB": "2015-04-12",
			"DNA": "",
			"SEX": "Doe"
		},  
		{    
			"ID": 1517216,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1725091",    
			"DATE_OF_BIRTH": "04/25/2015",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES SUNSHINE",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES SUNSHINE           **DNA**<br />D1725091                 ETA 24 19<br /> 02-02 87 VVE<br />",    
			"TATTOO": "RE: IMG LE: F12 ",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "02-02 87 VVE",
			"SIRE": "SG PROCTOR HILL FARM EVAN WILLIAM 85 +EE",
			"DAM": "SG IMAGINATION ACRES ALM DUDETTE 2*M 85 ++VV",	
			"NICKNAME": "Sunny",
			"DOB": "2015-04-25",
			"DNA": "**DNA**",
			"SEX": "Buck"
		},  
		{    
			"ID": 1543079,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1750882",    
			"DATE_OF_BIRTH": "05/01/2015",    
			"STARS": "     ",    
			"GOAT_NAME": "AGS DARK CORNER FARM MOON SHADOW",    
			"GOAT_PEDIGREE": "AGS DARK CORNER FARM MOON SHADOW     **DNA**<br />D1750882                 <br /> 02-02 86 VVV<br />",    
			"TATTOO": "RE: 1JN LE: F1 ",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "02-02 86 VVV",
			"SIRE": "AGS SC HOLLY SPGS LJ ",
			"DAM": "AGS SC HOLLY SPGS PNUT BUTTER",
			"NICKNAME": "Eclipse",
			"DOB": "2015-05-01",
			"DNA": "**DNA**",
			"SEX": "Buck"	
		},  
		{    
			"ID": 1566267,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1774052",    
			"DATE_OF_BIRTH": "04/04/2016",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES BLACK DAHLIA",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES BLACK DAHLIA        <br />D1774052                 PTI 4 -25<br /> 01-03 81 +V++<br />PTA           10M    1F    0P  -.20T<br />DEV           17     3     0<br />PTA$                 2     2     36R<br />PTA%  34R 08/20    2   1  11/19<br />",    
			"TATTOO": "RE: IMG LE: H5 ",    
			"CUSTOMER_ID_MATCH": 0,
			"FOR_SALE": false,
			"PRICE": "$400.00 open or $450.00 exposed or $550.00 confirmed bred by bloodtest/ultrasound",
			"SIRE": "SG PROCTOR HILL FARM EVAN WILLIAM 85 +EE +B",
			"DAM": "AGS COUNTRY BUMPKINS WN WINN-DIXIE 1*M 87 VVEV",
			"NICKNAME" : "Dahlia",
			"DOB": "2016-04-04",
			"DNA": "",
			"LA": "05-4 86 VVV+",
			"RETIRED": true,
			"SEX": "Doe"
		},  
		{    
			"ID": 1584587,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1792372",    
			"DATE_OF_BIRTH": "05/05/2016",  
			"GOAT_NAME": "IMAGINATION ACRES TWIST IT UP",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES TWIST IT UP         <br />D1792372                 <br />",    
			"TATTOO": "RE: IMG LE: H6 ",    
			"CUSTOMER_ID_MATCH": 0,  
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B +B  78 A+A",
			"DAM": "IMAGINATION ACRES RAINSTORM 3*M 81 ++++",
			"NICKNAME": "Pretzel",
			"DOB": "2016-05-05",
			"DNA": "",
			"STARS": "4*M ",
			"LA": "05-3 86 VVE+",
			"SEX": "Doe"
		},  
		{    
			"ID": 1584588,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1792373",    
			"DATE_OF_BIRTH": "05/05/2016",   
			"GOAT_NAME": "IMAGINATION ACRES MOVIE NIGHT",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES MOVIE NIGHT         <br />D1792373                 <br />",    
			"TATTOO": "RE: IMG LE: H8 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B +B 78 A+A",
			"DAM": "IMAGINATION ACRES RAINSTORM 3*M 81 ++++",
			"NICKNAME": "Popcorn",
			"DOB": "2016-05-05",
			"SEX": "Doe",
			"DNA": "",
			"STARS": ""
		},  
		{    
			"ID": 1584590,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1792375",    
			"DATE_OF_BIRTH": "05/08/2016",      
			"GOAT_NAME": "IMAGINATION ACRES WONTON SOUP",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES WONTON SOUP         <br />D1792375P                <br />",    
			"TATTOO": "RE: IMG LE: H10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B +B 78 A+A",
			"DAM": "SG IMAGINATION ACRES ALM DUDETTE 2*M 85 ++VV",
			"NICKNAME" : "Wonton",
			"DOB": "2016-05-08",
			"DNA": "",
			"STARS": "3*M ",
			"SEX": "Doe"
		},  
		/*{    
			"ID": 1584591,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1792376",    
			"DATE_OF_BIRTH": "05/21/2016",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES STORM CHASER",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES STORM CHASER        <br />D1792376P                <br />",    
			"TATTOO": "RE: IMG LE: H12 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B  78 A+A",
			"DAM": "IMAGINATION ACRES RHUBARB 2*M 83 +V++",
			"FOR_SALE": true,
			"PRICE": "$300 with her ADGA registration papers or $150.00 WITHOUT her ADGA registration papers as a pet only",	
			"NICKNAME": "Cyclone",
			"DOB": "2016-05-21",
			"DESCRIPTION": "Cyclone is a sweet 4 year old flashy polled doe. She has not settled for us after being exposed the past 2 years. She has been free martin tested by UC Davis Veterinary Genetic Labs and is negative so that is not the reason she hasn't settled. (Copy of test results are in her photo gallery below.) She is an easy keeper, mid ranking in our herd, and loves people as she was bottle raised. She is overweight which may contribute to not settling for breeding and would benefit from a weight loss program/fitness regime in her next herd.",
			"DNA": ""
		}, */ 
		{    
			"ID": 1627599,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1835284",    
			"DATE_OF_BIRTH": "05/12/2016",        
			"GOAT_NAME": "IMAGINATION ACRES PLAYINHOOKIE",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES PLAYINHOOKIE        <br />D1835284                 <br />",    
			"TATTOO": "RE: IMG LE: H11 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B +B 78 A+A",
			"DAM": "IMAGINATION ACRES POWDER 1*M 85 VVVV",
			"NICKNAME": "Sloane",
			"DOB": "2016-12-05",
			"DNA": "**DNA**",
			"STARS": "3*M ",
			"SEX": "Doe"
		},  
		{    
			"ID": 1673207,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1880832",    
			"DATE_OF_BIRTH": "05/11/2017",       
			"GOAT_NAME": "IMAGINATION ACRES WERTHERS",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES WERTHERS            <br />D1880832                 <br />",    
			"TATTOO": "RE: IMG LE: J6 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "SYCAMORE SS SS MOCHI *S 82 +++",
			"DAM": "IMAGINATION ACRES GODIVA 2*M 86 +V+V",
			"NICKNAME": "Werthers",
			"DOB": "2017-05-11",
			"DNA": "",
			"STARS": "3*M  ",
			"SEX": "Doe"
			//"FOR_SALE": true,
			//"PRICE": "$550.00"
		},  
		{    
			"ID": 1673209,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1880834",    
			"DATE_OF_BIRTH": "05/16/2017",       
			"GOAT_NAME": "IMAGINATION ACRES ORZO",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES ORZO                <br />D1880834                 <br />",    
			"TATTOO": "RE: IMG LE: J10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "IMAGINATION ACRES SUNSHINE 87 VVE",
			"DAM": "KYEEMA RIDGE MT JUST 2 SWEET 1*M 86 VEV+",
			"DOB": "2017-05-16",
			"NICKNAME": "Orzo",
			"DNA": "",
			"STARS": "2*M  ",
			"SEX": "Doe"	
		},  
		{    
			"ID": 1673211,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1880836",    
			"DATE_OF_BIRTH": "05/13/2017",        
			"GOAT_NAME": "IMAGINATION ACRES PEG LEG SUE",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES PEG LEG SUE         <br />D1880836                 ETA -8 -31<br />",    
			"TATTOO": "RE: IMG LE: J8 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "SG PROCTOR HILL FARM EVAN WILLIAM 85 +EE +B",
			"DAM": "IMAGINATION ACRES RAINSTORM 3*M 81 ++++",
			"DOB": "2017-05-13",
			"NICKNAME": "Peggy",
			"DNA": "",
			"STARS": "4*M ",
			"LA": "04-3 88 VVEV",
			"SEX": "Doe"
		},  
		{    
			"ID": 1673212,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1880837",    
			"DATE_OF_BIRTH": "05/16/2017",        
			"GOAT_NAME": "IMAGINATION ACRES DAIQUIRI",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES DAIQUIRI            <br />D1880837                 <br />",    
			"TATTOO": "RE: IMG LE: J15 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B +B 78 A+A",
			"DAM": "IMAGINATION ACRES RAIN CLOUD 86 V+VV",
			"NICKNAME": "Daiq",
			"DOB": "2017-05-16",
			"DNA": "",
			"STARS": "1*M ",
			"LA": "04-3 88 VVEV",
			"SEX": "Doe"
		},  
		{    
			"ID": 1673214,    
			"GOAT_SEX": "REFERENCE",   
			"OLD_REG_NUM": "D1880839",    
			"DATE_OF_BIRTH": "05/20/2017",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES DOUBLESTUFF *M 88 VVEV",   
			"GOAT_PEDIGREE": "IMAGINATION ACRES DOUBLESTUFF         <br />D1880839P                <br />",    
			"TATTOO": "RE: IMG LE: J16 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "DOZY DOE DOWN EB FRISCO GOLD B +B 78 A+A",
			"DAM": "IMAGINATION ACRES BLACK DAHLIA 86 VVV+",
			"DOB": "2017-05-20",
			"NICKNAME": "Doublestuff",
			"DNA": "",
			"LA": "04-3 88 VVEV",
			"SEX": "Doe"
		},  
		{    
			"ID": 1673215,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1880840",    
			"DATE_OF_BIRTH": "05/21/2017",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES RUBY",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES RUBY                <br />D1880840                 ETA -26 -53<br />",    
			"TATTOO": "RE: IMG LE: J18 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "SG PROCTOR HILL FARM EVAN WILLIAM 85 +EE +B",
			"DAM": "IMAGINATION ACRES RHUBARB 2*M 83 +V++",
			"DOB": "2017-05-21",
			"NICKNAME": "Ruby",
			"DNA": "",
			"LA": "04-3 81 VVEA",
			"RETIRED": true,
			"SEX": "Doe"
		},   
		{    
			"GOAT_SEX": "REFERENCE", 
			"ID": 1860729,    
			"SEX": "Buck",    
			"OLD_REG_NUM": "D2068271",    
			"DATE_OF_BIRTH": "12/03/2018",    
			"STARS": "*B   ",    
			"GOAT_NAME": "THIS & THAT ACRES MOCHA",    
			"GOAT_PEDIGREE": "THIS & THAT ACRES MOCHA               <br />D2068271       *B        <br />",    
			"TATTOO": "RE: DCMG LE: L11 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "REBELWOOD GJ DENALI *B",
			"DAM": "SG REBELWOOD O SWEET BAOBAB 2*M ELITE LIST 2019, 2020",
			"DOB": "2018-12-03",
			"NICKNAME": "Mocha",
			"DNA": "**DNA**",
			"RETIRED": true,	
		},
		{    "ID": 1432603,    
			"GOAT_SEX": "FROZEN",    
			"OLD_REG_NUM": "D1640673",    
			"DATE_OF_BIRTH": "02/13/2013",    
			"STARS": "+*B  ",    
			"GOAT_NAME": "JACKSONS ROYAL BW BAYLINER",    
			"GOAT_PEDIGREE": "JACKSONS ROYAL BW BAYLINER           **DNA**<br />D1640673       +*B       ETA -84 -129<br /> 05-05 91 EEE<br />PTA                            -.90T<br />PTA$                      39R<br />PTA%                          11/19<br />D/AV                             81<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "05-05 91 EEE",
			"SIRE": "AGS TWIN CREEKS BH BAY WATCH ++B",
			"DAM": "WOOD BRIDGE FARM VEGAS 1*M  88 VEVV",
			"NICKNAME": "Bayliner",
			"PHOTO_CREDIT": "Photo is used with permission and credited to Carol at Diji Farms",
			"DOB": "2013-02-13",
			"DESCRIPTION": "To learn more about Bayliner, please visit Diji Farm's buck page on their <a href=\"http://www.dijifarm.com/\" target=\"_blank\">website.</a>",
			"DNA": "**DNA**"
		},
		{    "ID": "Spicey",    
			"GOAT_SEX": "FROZEN",    
			"OLD_REG_NUM": "D2017416",    
			"DATE_OF_BIRTH": "03/10/2019",    
			"STARS": "*B  ",    
			"GOAT_NAME": "TIL-RIV CT SPICEY SOLUTION",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "STAYAHILE FIN CAMERA TECH *B",
			"DAM": "GCH RAINEY VALLEY SGW CINNAMON 2*M VEEE91",
			"NICKNAME": "Spicey",
			"PHOTO_CREDIT": "Photo is credited to Til-Riv Farm",
			"DOB": "2019-03-10",
			"DESCRIPTION": "To learn more about Spicey, please visit Til-Rive Farm their <a href=\"https://till-rivfarm.weebly.com/\" target=\"_blank\">website.</a>",
			"DNA": "**DNA**"
		},
		{    
			"ID": 1484860,    
			"GOAT_SEX": "FROZEN",    
			"OLD_REG_NUM": "D1692781",    
			"DATE_OF_BIRTH": "01/05/2014",    
			"STARS": "*B   ",    
			"GOAT_NAME": "DIJI FARM BW GLACIER BAY",    
			"GOAT_PEDIGREE": "DIJI FARM BW GLACIER BAY             **DNA**<br />D1692781       *B        ETA -63 -92<br /> 05-07 87 V+E<br />PTA                            -.70T<br />PTA$                      42R<br />PTA%                          11/19<br />D/AV                             83<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS TWIN CREEKS BH BAY WATCH ++B",
			"DAM": "DIJI FARM WL SNOW WHITE  1*M  88 VVEV",
			"LA": "05-07 87 V+E",
			"NICKNAME": "Glacier",
			"PHOTO_CREDIT": "Photo is used with permission and credited to Carol at Diji Farms",
			"DOB": "2014-01-05",
			"DESCRIPTION": "To learn more about Glacier, please visit Diji Farm's buck page on their <a href=\"http://www.dijifarm.com/\" target=\"_blank\">website.</a>",
			"DNA": "**DNA**"
		},
		{    
			"ID": 1622322,    
			"GOAT_SEX": "FROZEN",    
			"OLD_REG_NUM": "D1830007",    
			"DATE_OF_BIRTH": "05/07/2016",    
			"STARS": "     ",    
			"GOAT_NAME": "BUSY BROWSERS RS WEDDING SINGR",    
			"GOAT_PEDIGREE": "BUSY BROWSERS RS WEDDING SINGR       **DNA**<br />D1830007                 <br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "ROSASHARN RS B MY ROCK STAR *B",
			"DAM": "ALGEDI FARM DJ WEDDED BLISS",
			"NICKNAME": "Robbie",
			"PHOTO_CREDIT": "Photo is used with permission and credited to Jennifer at PrimRose Hill Nigerians",
			"DOB": "2016-05-07",	
			"DESCRIPTION": "To learn more about Wedding Singr aka Robbie, please visit PrimRose Hill Nigerians' buck page on their <a href=\"https://rosehillfarms.net/\" target=\"_blank\">website.</a>",
			"DNA": "**DNA**"
		},
		{    
			"ID": 1512239,    
			"GOAT_SEX": "FROZEN",   
			"OLD_REG_NUM": "D1720128",    
			"DATE_OF_BIRTH": "04/09/2015",    
			"STARS": "     ",    
			"GOAT_NAME": "LADY WALKERS M DOUBLE GALAXY",    
			"GOAT_PEDIGREE": "LADY WALKERS M DOUBLE GALAXY         **DNA**<br />D1720128                 <br /> 02-02 90 EEV<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0 ,
			"LA": "02-02 90 EEV",
			"SIRE": "ROSASHARN SP MERCURY ++*B 92 EEE",
			"DAM": "CHENANGO-HILLS LIANTA 84 +V+V",
			"NICKNAME": "Galaxy",
			"PHOTO_CREDIT": "Photo is used with permission and credited to Lady Walkers Half Moon Farms",
			"DOB": "2015-04-09",
			"DESCRIPTION": "To learn more about Galaxy, please visit Lady Walkers' herd sires page on their <a href=\"http://www.ladywalkers.com/\" target=\"_blank\">website.</a>",
			"DNA": "**DNA**"
		},
		{    
			"ID": 1330604,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1538839",    
			"DATE_OF_BIRTH": "04/01/2009",    "STARS": "*S     ",    
			"GOAT_NAME": "AGS CORNERSTONE FARM ALMON",    
			"GOAT_PEDIGREE": "AGS CORNERSTONE FARM ALMON           **DNA**<br />D1538839                 <br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS CORNERSTONE FARM CESAR",
			"DAM": "AGS CORNERSTONE FARM ASRIEL ",
			"NICKNAME": "Dude",
			"DOB": "2009-04-01",
			"SEX": "Buck",
			"DNA": "**DNA**"
		},
		{    
			"ID": 1502748,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1710639",    
			"DATE_OF_BIRTH": "04/19/2014",  
			"GOAT_NAME": "SG PROCTOR HILL FARM EVAN WILLIAM",    
			"GOAT_PEDIGREE": "PROCTOR HILL FARM EVAN WILLIAM       **DNA**<br />D1710639              SG PTI 19 9<br /> 03-03 85 +EE<br />PTA           19M    0F    1P   0T<br />PTA$                 2     3     42R<br />PTA%  42R 08/20   -2   1  11/19<br />D/AV         570    33    26     84<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "03-03 85 +EE",
			"SIRE": "NC PROMISEDLAND RB BOLERO *B  85 +EV",
			"DAM": "PROCTOR HILL FARM RT DESTARE 1*M  89 VVVV",
			"NICKNAME": "Evan",
			"DOB": "2014-04-19",
			"SEX": "Buck",
			"DNA": "**DNA**",
			"STARS": "+B"
		},
		{    
			"ID": 1531019,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1738859",    
			"DATE_OF_BIRTH": "06/11/2015",        
			"GOAT_NAME": "DOZY DOE DOWN EB FRISCO GOLD B",    "GOAT_PEDIGREE": "DOZY DOE DOWN EB FRISCO GOLD B       **DNA**<br />D1738859P                <br /> 02-01 78 A+A<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "02-01 78 A+A",
			"SIRE": "WOOLY DOG DOWN ETIENNE BLEU  81 AVV",
			"DAM": "GYPSY MOON DH MAD TEAPARTY 1*M 87 VVVV",
			"NICKNAME": "Fritz",
			"DOB": "2015-06-11",
			"SEX": "Buck",
			"DNA": "**DNA**",
			"STARS": "+B "			
		},
		{    
			"ID": 1545696,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1753494",    
			"DATE_OF_BIRTH": "08/29/2015",      
			"GOAT_NAME": "SYCAMORE SS SS MOCHI",    
			"GOAT_PEDIGREE": "SYCAMORE SS SS MOCHI                 **DNA**<br />D1753494                 <br /> 01-10 82 +++<br />",    
			"TATTOO": "-",   
			"CUSTOMER_ID_MATCH": 0,  
			"LA" : "01-10 82 +++",
			"SIRE": "SYCAMORE SS MG EXCALIBER *B  83 +V+",
			"DAM": "KAAPIO ACRES SY MERIDA 1*M 86 +EE+",
			"NICKNAME": "Mochi",
			"DOB": "2015-08-29",
			"SEX": "Buck",
			"DNA": "**DNA**",
			"STARS": "*S"
		},
		{    
			"ID": 1285364,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1493681",    
			"DATE_OF_BIRTH": "02/27/2006",    
			"STARS": "1*M  ",    
			"GOAT_NAME": "AGS KID'N ACRES MM ATHENA",    
			"GOAT_PEDIGREE": "AGS KID'N ACRES MM ATHENA             <br />D1493681       1*M       PTI -23 -38<br /> 09-02 86 VVVV<br />PTA           -5M    0F   -1P  -.20T<br />DEV            0     0     0<br />PTA$                -1    -2     31R<br />PTA%  17R 08/20    1  -2  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     9-00  298   500  6.8  34  4.6  23   80  40<br />  LIFE       298   500  6.8  34  4.6  23<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"LA":"09-02 86 VVVV",
			"SIRE": "AGS PROMISEDLAND LD MINUTE MAN",
			"DAM": "AGS CAESAR'S VILLA R RISING STAR",
			"NICKNAME": "Athena",
			"DOB": "2006-02-27",
			"SEX": "Doe",
			"DNA": ""			
		},
		{    
			"ID": 1472767,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1680712",    
			"DATE_OF_BIRTH": "05/20/2013",    
			"STARS": "1*M  ",    
			"GOAT_NAME": "IMAGINATION ACRES POWDER",    
			"GOAT_PEDIGREE": "IMAGINATION ACRES POWDER              <br />D1680712       1*M       PTI -126 -170<br /> 05-02 85 VVVV<br />PTA          -21M   -2F   -1P  -.80T<br />DEV         -121    -9    -5<br />PTA$                -4    -6     44R<br />PTA%  51R 08/20   -7  -2  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     1-10  305   310  6.8  21  4.5  14   79  40<br />           315   320  6.9  22  4.4  14   79  40<br />  LIFE       315   320  6.9  22  4.4  14<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"LA":"05-02 85 VVVV",
			"SIRE": "END OF THE LINE CUPID +B",
			"DAM": "IMAGINATION ACRES ALM SPICE ",
			"NICKNAME": "Powder",
			"DOB": "2013-05-20",
			"SEX": "Doe",
			"DNA": ""			
		},
		{    
			"ID": 1504991,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "D1712882",   
			"DATE_OF_BIRTH": "05/10/2009",    
			"STARS": "1*M  ",    
			"GOAT_NAME": "AGS CAPRICOPIA'S ZELDA",   
			"GOAT_PEDIGREE": "AGS CAPRICOPIA'S ZELDA                <br />D1712882       1*M       PTI -70 -129<br /> 06-00 85 +VEV<br />PTA          -14M    0F   -1P  -.70T<br />DEV          -31     3    -3<br />PTA$                -1    -3     43R<br />PTA%  46R 08/20    3  -3  11/19<br />DHIR AGE  DIM  MILK   %  FAT   %  PRT DCR TTP<br />     5-10  305   410  8.3  34  4.1  17   80  40<br />           313   420  8.3  35  4  17   80  40<br />     8-10  262   470  7  33  4  19   91  40<br />  LIFE       575   890  7.6  68  4  36<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"LA": "06-00 85 +VEV",
			"SIRE": "CAPRICOPIA'S MAIN EVENT",
			"DAM": "AGS SHIREFARM TOUCHE'S VICTORIA",
			"NICKNAME": "Zelda",
			"DOB": "2009-05-10",
			"SEX": "Doe",
			"DNA": ""
		},
		{    
			"ID": 1255212,      
			"OLD_REG_NUM": "D1463601",    
			"DATE_OF_BIRTH": "04/18/2005",    
			"STARS": "++B  ",    "GOAT_NAME": "AGS DILL'S LD DERRINGER",    
			"GOAT_PEDIGREE": "AGS DILL'S LD DERRINGER              **DNA**<br />D1463601       ++B       PTI -173 -194<br />PTA          -46M   -4F   -2P  -.80T<br />PTA$                -8   -10     72R<br />PTA%  68R 08/20   -.10  -4  11/20<br />D/AV         822    50    37     86<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"GOAT_SEX": "FROZEN", 
			"NICKNAME": "Derringer",
			"DESCRIPTION": "To learn more about Derringer, please visit Dill's A Little Goat Farm reference sires page on their <a href=\"https://www.dillsalittlegoatfarm.com/\" target=\"_blank\">website.</a>",
			"SIRE": "ARMCH AGS TWIN CREEKS LS LUCK OFTHE DRAW +*S,E",
			"DAM": "AGS PROMISEDLAND CP FIDDLE DEEDEE 2*D,VG",
			"DOB": "2005-04-18",
			"PHOTO_CREDIT" : "Photo is credited to and belongs to Dill's A Little Goat Farm",
			"DNA": "**DNA**"		
		},
		{    
			"ID": 1424646,    
			"OLD_REG_NUM": "D1632723",    
			"DATE_OF_BIRTH": "03/27/2013",    
			"STARS": "++*B ",    
			"GOAT_NAME": "DILL'S D TWO-STEP",    
			"GOAT_PEDIGREE": "DILL'S D TWO-STEP                    **DNA**<br />D1632723       ++*B      PTI -81 -134<br />PTA           34M   -2F    1P  -.70T<br />PTA$                 1     0     76R<br />PTA%  74R 08/20   -.17  -1  11/20<br />D/AV         858    51    38     85<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"NICKNAME": "TwoStep",
			"GOAT_SEX": "FROZEN",
			"DESCRIPTION": "To learn more about TwoStep, please visit Dill's A Little Goat Farm sires page on their <a href=\"https://www.dillsalittlegoatfarm.com/\" target=\"_blank\">website.</a>",
			"SIRE": "AGS DILL'S LD DERRINGER ++B",
			"DAM": " SGCH NC PROMISEDLAND PAL MACARENA 5*M",
			"DOB": "2013-03-27",
			"PHOTO_CREDIT" : "Photo is credited to and belongs to Dill's A Little Goat Farm",
			"DNA": "**DNA**"			
		},
		{    
			"ID": 1467989,      
			"OLD_REG_NUM": "D1675946",    
			"DATE_OF_BIRTH": "03/29/2014",    
			"STARS": "*B   ",    
			"GOAT_NAME": "HIDDEN GEMS CZ CABO",    
			"GOAT_PEDIGREE": "HIDDEN GEMS CZ CABO                  **DNA**<br />D1675946       *B        <br /> 02-01 89 VEE<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"NICKNAME": "Cabo",
			"GOAT_SEX": "FROZEN",
			"SIRE": "ROSASHARN NP HONEY MOON CRUZ +*B 87 VVE",
			"DAM": "SG HIDDEN GEMS GK SOUTHERN CHARM 2*M 91 EEEV",
			"DOB": "2014-03-29",
			"LA": "02-01 89 VEE",
			"PHOTO_CREDIT" : "Photo is credited to and belongs to Hidden Palms Farm",
			"DESCRIPTION": "To learn more about Cabo's dam, please visit Stay A While Farm on their <a href=\"http://stayawhilefarm.com/does.html\" target=\"_blank\">website.</a> To learn more about Cabo's sire please visit Bella Notte Farm on their <a href=\"https://bellanottefarm.com/portfolio/rosasharn-np-honey-moon-cruz-b/\" target=\"_blank\">website.</a>",
			"DNA": "**DNA**"			
		},
		{    
			"ID": 1547283,      
			"OLD_REG_NUM": "D1755068",    
			"DATE_OF_BIRTH": "03/12/2015",    
			"STARS": "*B   ",    
			"GOAT_NAME": "CASTLE ROCK BUGATTI",    
			"GOAT_PEDIGREE": "CASTLE ROCK BUGATTI                  **DNA**<br />D1755068       *B        <br /> 04-04 90 VEE<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"NICKNAME": "Bugatti",
			"GOAT_SEX": "FROZEN",
			"SIRE": "CASTLE ROCK WILD & CRAZY GUY *B",
			"DAM": "GCH AGS CRF CASTLE ROCK TESLA 2*M 89 VVEE",
			"DOB": "2015-03-12",
			"LA": "04-04 90 VEE",
			"PHOTO_CREDIT": "Photo is credited to and belongs to Benoit Farms",
			"DESCRIPTION": "To learn more about Bugatti, please visit Benoit Farm and Livestock on their <a href=\"https://www.benoitfarmsandlivestock.com/bugatti.html\" target=\"_blank\">website</a> and <a href=\"https://www.facebook.com/benoitfarms2017/\" target=\"blank\">facebook page.</a>",	
			"DNA": "**DNA**"		
		},
		{    
			"ID": 1733992,     
			"OLD_REG_NUM": "D1941657",    
			"DATE_OF_BIRTH": "04/15/2016",    
			"STARS": "     ",    
			"GOAT_NAME": "KYEEMA RIDGE LOVESTRUCK ROMEO",    
			"GOAT_PEDIGREE": "KYEEMA RIDGE LOVESTRUCK ROMEO         <br />D1941657                 <br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"NICKNAME": "Romeo",
			"GOAT_SEX": "FROZEN",
			"SIRE": "KYEEMA RIDGE BANJO PATERSON 91 EEE",
			"DAM": "OLD MOUNTAIN FARM YELAINA 88 VEVV",
			"DOB": "2016-04-15",
			"PHOTO_CREDIT": "Photo is credited to and belongs to Kyeema Ridge",
			"DESCRIPTION": "To learn more about Romeo, please visit Kyeema Ridge Nigerian Dwarf Dairy Goats on their <a href=\"https://www.kyeemaridge.com/lovestruck-romeo.html\" target=\"_blank\">website.</a>",	
			"DNA": "**DNA**"
		},
		{    
			"ID": 1589040,     
			"OLD_REG_NUM": "D1796825",    
			"DATE_OF_BIRTH": "04/17/2016",    
			"STARS": "     ",    
			"GOAT_NAME": "CH GYPSY MOON WP WARPARTY",    
			"GOAT_PEDIGREE": "GYPSY MOON WP WARPARTY               **DNA**<br />D1796825              CH PTI -126 -103<br />PTA          -53M   -3F   -2P  -.30T<br />PTA$                -9   -10     33R<br />PTA%  43R 08/20   -7  -1  11/20<br />D/AV         686    41    29     84<br />",    
			"TATTOO": "-",    
			"CUSTOMER_ID_MATCH": 0,
			"NICKNAME": "WarParty",
			"GOAT_SEX": "FROZEN",
			"SIRE": "CH GYPSY MOON SB WAR-PAINT *B 90 EVE",
			"DAM": "CH GYPSY MOON SB MIA MEANY 90 VEVE",
			"DOB": "2016-04-17",	
			"PHOTO_CREDIT": "Photo is credited to and belongs to Top Flight Farms",
			"DESCRIPTION": "To learn more about WarParty, please visit Top Flight Farms Inc. on their <a href=\"https://www.topflightfarms.net/chgypsy-moon-wp-warparty.html\" target=\"_blank\">website.</a>",	
			"DNA": "**DNA**"		
		},
		{    
			"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2156802",    
			"DATE_OF_BIRTH": "03/09/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES CLEO",       
			"TATTOO": "RE: IMG LE: N2 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS DARK CORNER FARM MOON SHADOW 86 VVV",
			"DAM": "IMAGINATION ACRES ACRES DOUBLESTUFF 88 VVEV",
			"DOB": "2021-03-09",
			"NICKNAME": "Cleo",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
			/*"DESCRIPTION": "Cleo is a super friendly and adventurous polled, brown eyed, gold and white doeling. Her dam is a first freshner with impressive teat size and a capacious udder with beautiful medial suspensory. Cleo has a strong uphill stance, lots of rear width and a lovely correct Nigerian head with a graceful ear set. Cleo is weaned from the bottle and ready to go. She is ADGA registered with her registration papers in hand, up to date on all age appropriate vaccines, deworming and coccidia prevention."*/
		},
		/*{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "",    
			"DATE_OF_BIRTH": "05/09/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES BLANCHE",      
			"TATTOO": "RE: IMG LE: N5 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS DARK CORNER FARM MOON SHADOW 86 VVV",
			"DAM": "IMAGINATION ACRES ACRES PEG LEG SUE 4*M 88 VVEV",
			"DOB": "2021-05-09",
			"NICKNAME": "Blanche",
			"DNA": "",
			"FOR_SALE": false
		},*/
		/*{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "",    
			"DATE_OF_BIRTH": "05/10/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES SOPHIA",    
			"TATTOO": "RE: IMG LE: N9 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS DARK CORNER FARM MOON SHADOW 86 VVV",
			"DAM": "IMAGINATION ACRES ACRES BLACK DAHLIA 86 VVV+",
			"DOB": "2021-05-10",
			"NICKNAME": "Sophia",
			"DNA": "",
			"FOR_SALE": true,
			"PRICE": "$450.00",
			"DESCRIPTION": "Sophia is a super friendly and adventurous disbudded, brown eyed, solid cream doeling. Her dam is a third freshner with beautiful udder shape and attachments. Sophia is long and level with strong dairy character. She is weaned off the bottle. She is ADGA registered with her registration papers in hand, up to date on all age appropriate vaccines, deworming and coccidia prevention."
		},*/
		/*{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "",    
			"DATE_OF_BIRTH": "05/10/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES ROSE NYLUND",     
			"TATTOO": "RE: IMG LE: N8 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS DARK CORNER FARM MOON SHADOW 86 VVV",
			"DAM": "IMAGINATION ACRES ACRES BLACK DAHLIA 86 VVV+",
			"DOB": "2021-05-10",
			"NICKNAME": "Rose",
			"DNA": "",
			"FOR_SALE": false
		},*/
		/*{    
			//"ID": 3092021,    
			"GOAT_SEX": "BUCK",    
			"OLD_REG_NUM": "",    
			"DATE_OF_BIRTH": "05/10/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES N7",  
			"TATTOO": "RE: IMG LE: N7 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "AGS DARK CORNER FARM MOON SHADOW 86 VVV",
			"DAM": "IMAGINATION ACRES ACRES PEG LEG SUE 4*M 88 VVEV",
			"DOB": "2021-05-09",
			"NICKNAME": "Stanley",
			"DNA": "",
			"FOR_SALE": true,
			"PRICE": "$300.00 ADGA registered",
			"DESCRIPTION": "Stanley is a sweet and easy going disbudded, brown eyed, buckskin with heavy white overlay buckling. His dam is a 2nd freshner with excellent capacity, teat size and udder shape. Stan is long and level with strong dairy character and masculine uphill stance. He is weaned off the bottle, and he is up to date on all age appropriate vaccines, deworming and coccidia prevention."
		},*/

		{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2242330",    
			"DATE_OF_BIRTH": "05/29/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES TWIX AI",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "KYEEMA RIDGE LOVESTRUCK ROMEO",
			"DAM": "IMAGINATION ACRES ACRES TWIST IT UP 4*M 86 VVE+",
			"DOB": "2021-05-29",
			"NICKNAME": "Twix",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
		},
		{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2218565",    
			"DATE_OF_BIRTH": "03/6/2022",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES VANTABLACK",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "THIS AND THAT ACRES MOCHA *B",
			"DAM": "IMAGINATION ACRES DAIQUIRI *M 88 VVEV",
			"DOB": "2022-03-06",
			"NICKNAME": "Vanta",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
		},
		{    
			//"ID": 3092021,    
			"GOAT_SEX": "DOE",    
			"OLD_REG_NUM": "D2223334",    
			"DATE_OF_BIRTH": "03/24/2022",    
			"STARS": "     ",    
			"GOAT_NAME": "IMAGINATION ACRES JUST 2 MUCH",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "THIS AND THAT ACRES MOCHA *B",
			"DAM": "KYEEMA RIDGE MT JUST TOO SWEET *M 86 VEV+",
			"DOB": "2022-03-24",
			"NICKNAME": "Extra",
			"DNA": "",
			"FOR_SALE": true,
			"GOAT_PEDIGREE": "IMAGINATION ACRES CLEO         <br />D2156802               <br />",
			"PRICE": "$400.00",
		},
		{    
			//"ID": 3092021,    
			"GOAT_SEX": "REFERENCE",    
			"OLD_REG_NUM": "",    
			"DATE_OF_BIRTH": "03/01/2021",    
			"STARS": "     ",    
			"GOAT_NAME": "KIDS OF KAITLIN'S DD NOX",     
			"TATTOO": "RE: IMG LE: N10 ",    
			"CUSTOMER_ID_MATCH": 0,
			"SIRE": "KIDS OF KAITLIN'S DODGE DART",
			"DAM": "MAGNOLIA FARM ANNE BONNEY",
			"DOB": "2021-03-01",
			"NICKNAME": "Nox",
			"DNA": "",
			"FOR_SALE": false,
			"SEX": "Buck"
		},		
	]
};

export default GoatList;
