import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import GoatSalesList from './GoatSalesList';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery.css';
import KidSalesPhotos from './Images/KidSalesPhotos';
import { HashLink as Link } from 'react-router-hash-link';
import MainPhotos from './Images/MainPhotos.js';
import GoatPhotos from './Images/GoatPhotoArrays.js';
//import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '1000px',
		margin: '1rem',
	},
	cardMobile: {
		maxWidth: '390px',
		margin: '1rem',
	},
	cards: {
		display: 'grid',
		justifyItems: 'center'
	},
	mainImage: {
		maxWidth: '800px'
	},
	mainImageMobile: {
		maxWidth: '300px'
	},
	newLine: {
		whiteSpace: 'pre-line'
	}
}));

export default function SalesProfiles(props) {
  const {isNotMobile} = props;
  const classes = useStyles();
  
  return (
    <div className={classes.cards}>
			<Typography variant="h5">
			{'Current Goats Available For Sale:'}
			</Typography><br/>
    	{ /*GoatSalesList?.BuckKids2022?.map((goat) =>
    		 (
		    	<Card className={classes.card}>
						<CardHeader className={classes.newLine} title={`Buck Kid ${goat.Tattoo}`}>
						</CardHeader>
						<CardContent>
							<Typography variant="h6">
								{goat.Name}
							</Typography>
							{
								KidSalesPhotos[goat.Tattoo] && <ImageGallery showFullscreenButton={false} items={KidSalesPhotos[goat.Tattoo]} /> 
							}
							{ goat.Frozen &&
										<Link to={`/bucksonice#${goat.SireNickname}`}>
											<Typography>
												{`Sire: ${goat.Sire}`}
											</Typography>
										</Link>
									}
									{goat.Reference &&
										<Link to={`/reference#${goat.SireNickname}`}>
											<Typography>
												{`Sire: ${goat.Sire}`}
											</Typography>
										</Link>
									}
									{!goat.Frozen && !goat.Reference &&
										<Link to={`/bucks#${goat.SireNickname}`}>
											<Typography>
												{`Sire: ${goat.Sire}`}
											</Typography>
										</Link>
								}
									{goat.DamReference &&
										<Link to={`/reference#${goat.DamNickname}`}>
											<Typography>
												{`Dam: ${goat.Dam}`}
											</Typography>
										</Link>
									}
									{!goat.DamReference &&
										<Link to={`/does#${goat.DamNickname}`}>
											<Typography>
												{`Dam: ${goat.Dam}`}
											</Typography>
										</Link>
								}
							<Typography>
								{`Date of Birth: ${goat.Kidded}`}
							</Typography>
							<Typography>
								{goat.Description}
							</Typography>
						</CardContent>
					</Card>
				))
			}
			{ GoatSalesList?.DoeKids2022?.map((goat) =>
    		 (
		    	<Card className={classes.card}>
						<CardHeader className={classes.newLine} title={`Doe Kid ${goat.Tattoo}`}>
						</CardHeader>
						<CardContent>
							<Typography variant="h6">
								{goat.Name}
							</Typography>
							{
								KidSalesPhotos[goat.Tattoo] && <ImageGallery showFullscreenButton={false} items={KidSalesPhotos[goat.Tattoo]} /> 
							}
							{ goat.Frozen &&
										<Link to={`/bucksonice#${goat.SireNickname}`}>
											<Typography>
												{`Sire: ${goat.Sire}`}
											</Typography>
										</Link>
									}
									{goat.Reference &&
										<Link to={`/reference#${goat.SireNickname}`}>
											<Typography>
												{`Sire: ${goat.Sire}`}
											</Typography>
										</Link>
									}
									{!goat.Frozen && !goat.Reference &&
										<Link to={`/bucks#${goat.SireNickname}`}>
											<Typography>
												{`Sire: ${goat.Sire}`}
											</Typography>
										</Link>
								}
									{goat.Reference &&
										<Link to={`/reference#${goat.DamNickname}`}>
											<Typography>
												{`Dam: ${goat.Dam}`}
											</Typography>
										</Link>
									}
									{!goat.Reference &&
										<Link to={`/does#${goat.DamNickname}`}>
											<Typography>
												{`Dam: ${goat.Dam}`}
											</Typography>
										</Link>
								}
							<Typography>
								{`Date of Birth: ${goat.Kidded}`}
							</Typography>
							<Typography>
								{goat.Description}
							</Typography>
						</CardContent>
					</Card>
				))
			*/
				GoatSalesList?.Does?.map((goat) =>
    		 (
		    	<Card key={goat.ID} className={isNotMobile ? classes.card : classes.cardMobile} key={goat.NICKNAME}>
				<CardHeader className={classes.newLine} title={goat.GOAT_SEX !== 'FROZEN' && goat.NICKNAME ? `${goat.GOAT_NAME} ${goat.STARS} ${goat.DNA} \n aka "${goat.NICKNAME}"` : `${goat.GOAT_NAME}  ${goat.STARS} ${goat.DNA}`}>
				</CardHeader>
				<CardContent>
					{goat.NICKNAME && MainPhotos[goat.NICKNAME] ?
						<img src={MainPhotos[goat.NICKNAME]} alt={goat.NICKNAME} className={isNotMobile ?  classes.mainImage : classes.mainImageMobile }/>
						: 
						<><Typography variant="caption">
							{'Photo coming soon!'}
						</Typography><br/><br/></>
					}
					<Typography>
						{`Sex: ${goat.GOAT_SEX}`}
					</Typography>
					<Typography>
						{`Date of Birth: ${goat.DATE_OF_BIRTH}`}
					</Typography>
					{goat.LA &&
						<Typography>
							{`Linear Appraisal Age/Score: ${goat.LA}`}
						</Typography>
					}
					<br/>
					<Typography>
						{`Sire: `}
					</Typography>
					{goat.SireReference && 
						<Link to={{
							pathname: "/reference",
							hash: goat.SireNickname
						}}>
							<Typography>
								{goat.SIRE}
							</Typography>
						</Link>
					}
					{goat.SireFrozenReference &&
						<Link to={{
							pathname: "/bucksonice",
							hash: goat.SireNickname
						}}>
							<Typography>
								{goat.SIRE}
							</Typography>
						</Link>
					}
					<Typography>
						{`Dam: `}
					</Typography>
					{goat.DamReference && 
						<Link to={{
							pathname: "/reference",
							hash: goat.DamNickname
						}}>
							<Typography>
								{goat.DAM}
							</Typography>
						</Link>
					}
					{!goat.DamReference &&
						<Link to={{
							pathname: "/does",
							hash: goat.DamNickname
						}}>
							<Typography>
								{goat.DAM}
							</Typography>
						</Link>
					}
					{
						goat.GOAT_PEDIGREE && <a target="_blank"  rel="noopener noreferrer" href={`https://adgagenetics.org/GoatDetail.aspx?RegNumber=${goat.OLD_REG_NUM.replace('D', 'D00')}`} >ADGA Genetics Pedigree Link</a>
					}
					<br/><br/>
					{
						GoatPhotos[goat.NICKNAME] && <ImageGallery showFullscreenButton={false} items={GoatPhotos[goat.NICKNAME]} /> 
					}
					<Typography variant="h6">
					{
						`Price: ${goat.PRICE}`
					}
					</Typography>
				</CardContent>
			</Card>	
				))
		}
    </div>
  );
}

SalesProfiles.propTypes = {
	isNotMobile: PropTypes.bool.isRequired
};
