//Cupid
import CupidPhoto0 from './Cupid/0.jpg';
import CupidPhoto1 from './Cupid/1.jpg';
import CupidPhoto2 from './Cupid/2.jpg';
import CupidPhoto5 from './Cupid/5.jpg';
import CupidPhoto6 from './Cupid/6.jpg';
import CupidPhoto7 from './Cupid/7.jpg';
import CupidPhoto8 from './Cupid/8.jpg';
import CupidPhoto9 from './Cupid/9.jpg';
import CupidPhoto10 from './Cupid/10.jpg';
import CupidPhoto11 from './Cupid/11.jpg';
import CupidPhoto12 from './Cupid/12.jpg';
import CupidPhoto13 from './Cupid/13.jpg';

//Sunny
import SunnyPhoto0 from './Sunny/0.jpg';
import SunnyPhoto1 from './Sunny/1.jpg';
import SunnyPhoto2 from './Sunny/2.jpg';
import SunnyPhoto3 from './Sunny/3.jpg';
import SunnyPhoto4 from './Sunny/4.jpg';
import SunnyPhoto5 from './Sunny/5.jpg';
import SunnyPhoto6 from './Sunny/6.jpg';

//Eclipse
import EclipsePhoto0 from './Eclipse/0.jpg';
import EclipsePhoto1 from './Eclipse/1.jpg';
import EclipsePhoto2 from './Eclipse/2.jpg';
import EclipsePhoto3 from './Eclipse/3.jpg';
import EclipsePhoto4 from './Eclipse/4.jpg';
import EclipsePhoto5 from './Eclipse/5.jpg';

//Rainstorm
import RainstormPhoto1 from './Rainstorm/1.jpg';
import RainstormPhoto2 from './Rainstorm/2.jpg';
import RainstormPhoto3 from './Rainstorm/3.jpg';
import RainstormPhoto4 from './Rainstorm/4.jpg';
import RainstormPhoto5 from './Rainstorm/5.jpg';

//Dudette
import DudettePhoto1 from './Dudette/1.jpg';
import DudettePhoto2 from './Dudette/2.jpg';
import DudettePhoto3 from './Dudette/3.jpg';
import DudettePhoto4 from './Dudette/4.jpg';
import DudettePhoto5 from './Dudette/5.jpg';
import DudettePhoto6 from './Dudette/6.jpg';

//Godiva
import GodivaPhoto1 from './Godiva/1.jpg';
import GodivaPhoto2 from './Godiva/2.jpg';
import GodivaPhoto3 from './Godiva/3.jpg';
import GodivaPhoto4 from './Godiva/4.jpg';
import GodivaPhoto5 from './Godiva/5.jpg';
import GodivaPhoto6 from './Godiva/6.jpg';
import GodivaPhoto7 from './Godiva/7.jpg';
import GodivaPhoto8 from './Godiva/8.jpg';

//Cloud
import CloudPhoto1 from './Cloud/1.jpg';
import CloudPhoto2 from './Cloud/2.jpg';
import CloudPhoto3 from './Cloud/3.jpg';
import CloudPhoto4 from './Cloud/4.jpg';
import CloudPhoto5 from './Cloud/5.jpg';
import CloudPhoto6 from './Cloud/6.jpg';
import CloudPhoto7 from './Cloud/7.jpg';
import CloudPhoto8 from './Cloud/8.jpg';

//Rhubarb
import RhubarbPhoto1 from './Rhubarb/1.jpg';
import RhubarbPhoto2 from './Rhubarb/2.jpg';
import RhubarbPhoto3 from './Rhubarb/3.jpg';
import RhubarbPhoto4 from './Rhubarb/4.jpg';
import RhubarbPhoto5 from './Rhubarb/5.jpg';
import RhubarbPhoto6 from './Rhubarb/6.jpg';
import RhubarbPhoto7 from './Rhubarb/7.jpg';
import RhubarbPhoto8 from './Rhubarb/8.jpg';
import RhubarbPhoto9 from './Rhubarb/9.jpg';
import RhubarbPhoto10 from './Rhubarb/10.jpg';

//Dulce
import DulcePhoto1 from './Dulce/1.jpg';
import DulcePhoto2 from './Dulce/2.jpg';
import DulcePhoto3 from './Dulce/3.jpg';
import DulcePhoto4 from './Dulce/4.jpg';
import DulcePhoto5 from './Dulce/5.jpg';
import DulcePhoto6 from './Dulce/6.jpg';
import DulcePhoto7 from './Dulce/7.jpg';

//Dahlia
import DahliaPhoto1 from './Dahlia/1.jpg';
import DahliaPhoto2 from './Dahlia/2.jpg';
import DahliaPhoto3 from './Dahlia/3.jpg';
import DahliaPhoto4 from './Dahlia/4.jpg';
import DahliaPhoto5 from './Dahlia/5.jpg';
import DahliaPhoto6 from './Dahlia/6.jpg';
import DahliaPhoto7 from './Dahlia/7.jpg';
import DahliaPhoto8 from './Dahlia/8.jpg';
import DahliaPhoto9 from './Dahlia/9.jpg';
import DahliaPhoto10 from './Dahlia/10.JPG';

//Popcorn
import PopcornPhoto1 from './Popcorn/1.jpg';
import PopcornPhoto2 from './Popcorn/2.jpg';
import PopcornPhoto3 from './Popcorn/3.jpg';
import PopcornPhoto4 from './Popcorn/4.jpg';
import PopcornPhoto5 from './Popcorn/5.jpg';
import PopcornPhoto6 from './Popcorn/6.jpg';
import PopcornPhoto7 from './Popcorn/7.jpg';
import PopcornPhoto8 from './Popcorn/8.jpg';

//Sloane
import SloanePhoto1 from './Sloane/1.jpg';
import SloanePhoto2 from './Sloane/2.jpg';
import SloanePhoto3 from './Sloane/3.jpg';
import SloanePhoto4 from './Sloane/4.jpg';
import SloanePhoto5 from './Sloane/5.jpg';
import SloanePhoto6 from './Sloane/6.jpg';
import SloanePhoto7 from './Sloane/7.jpg';

//Cyclone
import CyclonePhoto1 from './Cyclone/1.jpg';
import CyclonePhoto2 from './Cyclone/2.jpg';
import CyclonePhoto3 from './Cyclone/3.jpg';
import CyclonePhoto4 from './Cyclone/4.jpg';
import CyclonePhoto5 from './Cyclone/5.jpg';
import CyclonePhoto6 from './Cyclone/6.jpg';
import CyclonePhoto7 from './Cyclone/7.jpg';

//Pretzel
import PretzelPhoto1 from './Pretzel/1.jpg';
import PretzelPhoto2 from './Pretzel/2.jpg';
import PretzelPhoto3 from './Pretzel/3.jpg';
import PretzelPhoto4 from './Pretzel/4.jpg';
import PretzelPhoto5 from './Pretzel/5.jpg';
import PretzelPhoto6 from './Pretzel/6.jpg';
import PretzelPhoto7 from './Pretzel/7.jpg';
import PretzelPhoto8 from './Pretzel/8.JPG';

//Werthers
import WerthersPhoto1 from './Werthers/1.jpg';
import WerthersPhoto2 from './Werthers/2.jpg';
import WerthersPhoto3 from './Werthers/3.jpg';
import WerthersPhoto4 from './Werthers/4.jpg';
import WerthersPhoto5 from './Werthers/5.jpg';
import WerthersPhoto6 from './Werthers/6.jpg';

//Daiq
import DaiqPhoto1 from './Daiquiri/1.jpg';
import DaiqPhoto2 from './Daiquiri/2.jpg';
import DaiqPhoto3 from './Daiquiri/3.jpg';
import DaiqPhoto4 from './Daiquiri/4.jpg';
import DaiqPhoto5 from './Daiquiri/5.jpg';
import DaiqPhoto7 from './Daiquiri/7.JPG';

//Wonton
import WontonPhoto1 from './Wonton/1.jpg';
import WontonPhoto2 from './Wonton/2.jpg';
import WontonPhoto3 from './Wonton/3.jpg';
import WontonPhoto4 from './Wonton/4.jpg';
import WontonPhoto5 from './Wonton/5.jpg';
import WontonPhoto6 from './Wonton/6.jpg';
import WontonPhoto7 from './Wonton/7.jpg';

//Athena 
import AthenaPhoto1 from './Reference/Athena/1.jpg';
import AthenaPhoto2 from './Reference/Athena/2.jpg';
import AthenaPhoto3 from './Reference/Athena/3.jpg';
import AthenaPhoto4 from './Reference/Athena/4.jpg';

//Caddy 
import CaddyPhoto1 from './Reference/Caddy/1.jpg';
import CaddyPhoto2 from './Reference/Caddy/2.jpg';
import CaddyPhoto3 from './Reference/Caddy/3.jpg';
import CaddyPhoto4 from './Reference/Caddy/4.jpg';
import CaddyPhoto5 from './Reference/Caddy/5.jpg';

//Dixie
import DixiePhoto1 from './Reference/Dixie/1.jpg';
import DixiePhoto2 from './Reference/Dixie/2.jpg';
import DixiePhoto3 from './Reference/Dixie/3.jpg';
import DixiePhoto4 from './Reference/Dixie/4.jpg';

//Dude
import DudePhoto1 from './Reference/Dude/1.jpg';
import DudePhoto2 from './Reference/Dude/2.jpg';
import DudePhoto3 from './Reference/Dude/3.jpg';
import DudePhoto4 from './Reference/Dude/4.jpg';
import DudePhoto5 from './Reference/Dude/5.jpg';
import DudePhoto6 from './Reference/Dude/6.jpg';
import DudePhoto7 from './Reference/Dude/7.jpg';
import DudePhoto8 from './Reference/Dude/8.jpg';

//Zelda 
import ZeldaPhoto1 from './Reference/Zelda/1.jpg';
import ZeldaPhoto2 from './Reference/Zelda/2.jpg';
import ZeldaPhoto3 from './Reference/Zelda/3.jpg';
import ZeldaPhoto4 from './Reference/Zelda/4.jpg';

//Powder
import PowderPhoto1 from './Reference/Powder/1.jpg';
import PowderPhoto2 from './Reference/Powder/2.jpg';
import PowderPhoto3 from './Reference/Powder/3.jpg';
import PowderPhoto4 from './Reference/Powder/4.jpg';
import PowderPhoto5 from './Reference/Powder/5.jpg';
import PowderPhoto6 from './Reference/Powder/6.jpg';

//Evan
import EvanPhoto1 from './Reference/Evan/1.jpg';
import EvanPhoto2 from './Reference/Evan/2.jpg';
import EvanPhoto3 from './Reference/Evan/3.jpg';
import EvanPhoto4 from './Reference/Evan/4.jpg';
import EvanPhoto5 from './Reference/Evan/5.jpg';
import EvanPhoto6 from './Reference/Evan/6.jpg';
import EvanPhoto7 from './Reference/Evan/7.jpg';
import EvanPhoto8 from './Reference/Evan/8.jpg';
import EvanPhoto9 from './Reference/Evan/9.jpg';
import EvanPhoto10 from './Reference/Evan/10.jpg';
import EvanPhoto11 from './Reference/Evan/11.jpg';

//Fritz
import FritzPhoto1 from './Reference/Fritz/1.jpg';
import FritzPhoto2 from './Reference/Fritz/2.jpg';
import FritzPhoto3 from './Reference/Fritz/3.jpg';
import FritzPhoto4 from './Reference/Fritz/4.jpg';
import FritzPhoto5 from './Reference/Fritz/5.jpg';
import FritzPhoto6 from './Reference/Fritz/6.jpg';
import FritzPhoto8 from './Reference/Fritz/8.jpg';
import FritzPhoto9 from './Reference/Fritz/9.jpg';
import FritzPhoto10 from './Reference/Fritz/10.jpg';

//Ruby
import MochiPhoto1 from './Reference/Mochi/1.jpg';
import MochiPhoto2 from './Reference/Mochi/2.jpg';
import MochiPhoto3 from './Reference/Mochi/3.jpg';
import MochiPhoto4 from './Reference/Mochi/4.jpg';

//Doublestuff
import DoublestuffPhoto1 from './Doublestuff/1.jpg';
import DoublestuffPhoto2 from './Doublestuff/2.jpg';
import DoublestuffPhoto3 from './Doublestuff/3.jpg';
import DoublestuffPhoto4 from './Doublestuff/4.jpg';

//Ruby
import RubyPhoto1 from './Ruby/1.jpg';
import RubyPhoto2 from './Ruby/2.jpg';

//Peggy
import PeggyPhoto1 from './Peggy/1.jpg';
import PeggyPhoto2 from './Peggy/2.jpg';
import PeggyPhoto3 from './Peggy/3.jpg';

//Orzo
import OrzoPhoto1 from './Orzo/1.jpg';
import OrzoPhoto2 from './Orzo/2.jpg';
import OrzoPhoto3 from './Orzo/3.jpg';

//Mocha
import MochaPhoto1 from './Mocha/1.jpg';
import MochaPhoto2 from './Mocha/2.jpg';
import MochaPhoto3 from './Mocha/3.jpg';
import MochaPhoto4 from './Mocha/4.jpg';
import MochaPhoto5 from './Mocha/5.jpg';
import MochaPhoto6 from './Mocha/6.jpg';
import MochaPhoto8 from './Mocha/8.jpg';
import MochaPhoto9 from './Mocha/9.jpg';
import MochaPhoto10 from './Mocha/10.jpg';
import MochaPhoto11 from './Mocha/11.jpg';
import MochaMain from './Mocha/MochaMain.jpg';

//Cleo
import CleoPhoto1 from './Cleo/1.jpg';
import CleoPhoto2 from './Cleo/2.jpg';
import CleoPhoto3 from './Cleo/3.jpg';
import CleoPhoto4 from './Cleo/4.jpg';
import CleoPhoto5 from './Cleo/5.jpg';
import CleoPhoto6 from './Cleo/6.jpg';
import CleoPhoto7 from './Cleo/7.jpg';
import CleoPhoto8 from './Cleo/8.jpg';
import CleoPhoto9 from './Cleo/9.jpg';
import CleoPhoto10 from './Cleo/10.jpg';

//Sophia
import SophiaPhoto1 from './Sophia/2.jpg';

//N7
import N7Photo1 from './N7/2.jpg';

//N11
import N11Photo1 from './N11/1.jpg';
import N11Photo2 from './N11/2.jpg';

//N12
import N12Photo1 from './N12/1.jpg';

//Spicey
import SpiceyPhoto1 from './Spicey/SpiceyDam.jpg';
import SpiceyPhoto2 from './Spicey/SpiceyDam2.jpg';
import SpiceyPhoto3 from './Spicey/SpiceyDamUdder.jpg';
import SpiceyPhoto4 from './Spicey/SpiceySire.jpg';

//Vanta
import VantaPhoto1 from './Vanta/1.jpg';
import VantaPhoto2 from './Vanta/2.jpg';

//Twix
import TwixPhoto1 from './Twix/1.jpg';
import TwixPhoto2 from './Twix/2.jpg';
import TwixPhoto3 from './Twix/3.jpg';
import RomeoMainPhoto from './BucksOnIce/Romeo.jpg';

//Extra
import ExtraPhoto1 from './Extra/1.jpg';

export default {
	Cupid: [
		{
			original: CupidPhoto0,
			thumbnail: CupidPhoto0,
			description: 'Cupid as a 2 year old'
		},
		{
			original: CupidPhoto1,
			thumbnail: CupidPhoto1,
			description: 'Cupid as a yearling'
		},
		{
			original: CupidPhoto2,
			thumbnail: CupidPhoto2,
			description: 'Cupid at 5 years old'
		},
		{
			original: CupidPhoto5,
			thumbnail: CupidPhoto5,
			description: 'Cupid at 5 years old'
		},
		{
			original: CupidPhoto6,
			thumbnail: CupidPhoto6,
			description: 'Cupid as a senior kid'
		},
		{
			original: CupidPhoto7,
			thumbnail: CupidPhoto7,
			description: 'Cupid\'s Dam AGS LITTLE SLEEPY H SWEET PEA, photo credit to End of the Line Farm'
		},
		{
			original: CupidPhoto8,
			thumbnail: CupidPhoto8,
			description: 'Cupid\'s Dam AGS LITTLE SLEEPY H SWEET PEA udder, photo credit to End of the Line Farm'
		},
		{
			original: CupidPhoto9,
			thumbnail: CupidPhoto9,
			description: 'Cupid rear width photo'
		},
		{
			original: CupidPhoto10,
			thumbnail: CupidPhoto10,
			description: 'Cupid\'s Sire PHOENIX FARM KNIGHT RIDER +*B photo credit to End of the Line Farm'
		},
		{
			original: CupidPhoto11,
			thumbnail: CupidPhoto11,
			description: 'Cupid\'s paternal Grand Sire GCH TX TWINCREEKS FAX SUMMERKNIGHT*+B 90 VEE photo credit to photographer'
		},
		{
			original: CupidPhoto12,
			thumbnail: CupidPhoto12,
			description: 'Cupid\'s twin sister END OF THE LINE ON CLOUD 9, photo credit to End of the Line Farm'
		},
		{
			original: CupidPhoto13,
			thumbnail: CupidPhoto13,
			description: 'Cupid\'s twin sister END OF THE LINE ON CLOUD 9, photo credit to End of the Line Farm'
		}
	],
	Sunny: [
		{
			original: SunnyPhoto0,
			thumbnail: SunnyPhoto0,
			description: 'Sunny as a junior kid'
		},
		{
			original: SunnyPhoto1,
			thumbnail: SunnyPhoto1,
			description: 'Sunny as a junior kid'
		},
		{
			original: SunnyPhoto2,
			thumbnail: SunnyPhoto2,
			description: 'Sunny as a junior kid'
		},
		{
			original: SunnyPhoto3,
			thumbnail: SunnyPhoto3,
			description: 'Sunny as a junior kid'
		},
		{
			original: SunnyPhoto4,
			thumbnail: SunnyPhoto4,
			description: 'Sunny as a junior kid'
		},
		{
			original: SunnyPhoto5,
			thumbnail: SunnyPhoto5,
			description: 'Sunny as a junior kid'
		},
		{
			original: SunnyPhoto6,
			thumbnail: SunnyPhoto6,
			description: 'Sunny in rut Fall 2020'
		}
	],
	Eclipse: [
			{
			original: EclipsePhoto0,
			thumbnail: EclipsePhoto0,
			description: 'Eclipse at 5 years old'
		},
		{
			original: EclipsePhoto1,
			thumbnail: EclipsePhoto1,
			description: 'Eclipse as a junior kid'
		},
		{
			original: EclipsePhoto2,
			thumbnail: EclipsePhoto2,
			description: 'Eclipse as a 2 year old'
		},
		{
			original: EclipsePhoto3,
			thumbnail: EclipsePhoto3,
			description: 'Eclipse as a 2 year old'
		},
		{
			original: EclipsePhoto4,
			thumbnail: EclipsePhoto4,
			description: 'Eclipse\'s sire, photo credit to Dark Corner Farm'
		},
		{
			original: EclipsePhoto5,
			thumbnail: EclipsePhoto5,
			description: 'Eclipse\'s dam, photo credit to Dark Corner Farm'
		},
	],
	
	Dudette: [
		{
			original: DudettePhoto1,
			thumbnail: DudettePhoto1,
			description: 'Dudette first freshner udder'
		},
		{
			original: DudettePhoto2,
			thumbnail: DudettePhoto2,
			description: 'Dudette 2nd freshner udder'
		},
		{
			original: DudettePhoto3,
			thumbnail: DudettePhoto3,
			description: 'Dudette as a dry yearling'
		},
		{
			original: DudettePhoto4,
			thumbnail: DudettePhoto4,
			description: 'Dudette 3 years old'
		},
		{
			original: DudettePhoto5,
			thumbnail: DudettePhoto5,
			description: 'Dudette as a senior kid'
		},
		{
			original: DudettePhoto6,
			thumbnail: DudettePhoto6,
			description: 'Dudette 6 years old'
		},
	],
	Dulce: [
		{
			original: DulcePhoto1,
			thumbnail: DulcePhoto1,
			description: 'Dulce as a junior kid, photo credit to Kyeema Ridge'
		},
		{
			original: DulcePhoto2,
			thumbnail: DulcePhoto2,
			description: 'Dulce\'s sire BUTTIN\'HEADS MARTINIQUE +B 90 EEE, photo credit to Kyeema Ridge'
		},
		{
			original: DulcePhoto3,
			thumbnail: DulcePhoto3,
			description: 'Dulce\'s dam KYEEMA RIDGE HNC JUSTYN THYME 90 EEEV photo credit to Kyeema Ridge'
		},
		{
			original: DulcePhoto4,
			thumbnail: DulcePhoto4,
			description: 'Dulce 2 years old'
		},
		{
			original: DulcePhoto7,
			thumbnail: DulcePhoto7,
			description: 'Dulce first freshening udder'
		},
		{
			original: DulcePhoto5,
			thumbnail: DulcePhoto5,
			description: 'Dulce rear udder 2nd freshening'
		},
		{
			original: DulcePhoto6,
			thumbnail: DulcePhoto6,
			description: 'Dulce side udder 2nd freshening'
		}
	],
	Rainstorm: [
		{
			original: RainstormPhoto1,
			thumbnail: RainstormPhoto1,
			description: 'Rainstorm as a dry yearling'
		},
		{
			original: RainstormPhoto2,
			thumbnail: RainstormPhoto2,
			description: 'Rainstorm as a 2nd freshner'
		},
		{
			original: RainstormPhoto3,
			thumbnail: RainstormPhoto3,
			description: 'Rainstorm 3rd freshening udder, a few hours before kidding'
		},
		{
			original: RainstormPhoto4,
			thumbnail: RainstormPhoto4,
			description: 'Rainstorm with her daughter Rain Cloud'
		},
		{
			original: RainstormPhoto5,
			thumbnail: RainstormPhoto5,
			description: 'Rainstorm winning reserve champion junior'
		}
	],
	Rhubarb: [
		{
			original: RhubarbPhoto1,
			thumbnail: RhubarbPhoto1,
			description: 'Rhubarb as a junior kid'
		},
		{
			original: RhubarbPhoto2,
			thumbnail: RhubarbPhoto2,
			description: 'Rhubarb as a senior kid'
		},
		{
			original: RhubarbPhoto3,
			thumbnail: RhubarbPhoto3,
			description: 'Rhubarb as junior kid'
		},
		{
			original: RhubarbPhoto4,
			thumbnail: RhubarbPhoto4,
			description: 'Rhubarb as yearling first freshner'
		},
		{
			original: RhubarbPhoto5,
			thumbnail: RhubarbPhoto5,
			description: 'Rhubarb yearling first freshner udder'
		},
		{
			original: RhubarbPhoto6,
			thumbnail: RhubarbPhoto6,
			description: 'Rhubarb 3rd freshening rear udder'
		},
		{
			original: RhubarbPhoto7,
			thumbnail: RhubarbPhoto7,
			description: 'Rhubarb 3rd freshening side udder'
		},
		{
			original: RhubarbPhoto8,
			thumbnail: RhubarbPhoto8,
			description: 'Rhubarb 2020 buck kid'
		},
		{
			original: RhubarbPhoto9,
			thumbnail: RhubarbPhoto9,
			description: 'Rhubarb as a 2 year old'
		},
		{
			original: RhubarbPhoto10,
			thumbnail: RhubarbPhoto10,
			description: 'Rhubarb Fall 2020'
		}
	],
	Cloud: [
		{
			original: CloudPhoto1,
			thumbnail: CloudPhoto1,
			description: 'Cloud as a junior kid'
		},
		{
			original: CloudPhoto2,
			thumbnail: CloudPhoto2,
			description: 'Cloud as a senior kid'
		},
		{
			original: CloudPhoto3,
			thumbnail: CloudPhoto3,
			description: 'Cloud in the Best in Show Lineup at the VA State Fair 2015'
		},
		{
			original: CloudPhoto4,
			thumbnail: CloudPhoto4,
			description: 'Cloud wins Reserve Grand Champion junior at the MDGA Spring Show 2015'
		},
		{
			original: CloudPhoto5,
			thumbnail: CloudPhoto5,
			description: 'Cloud wins Grand and Best Junior in Show'
		},
		{
			original: CloudPhoto6,
			thumbnail: CloudPhoto6,
			description: 'Cloud first freshner udder'
		},
		{
			original: CloudPhoto7,
			thumbnail: CloudPhoto7,
			description: 'Cloud with her dam Rainstorm'
		},
		{
			original: CloudPhoto8,
			thumbnail: CloudPhoto8,
			description: 'Cloud wins BIS and GRCH Junior'
		}
	],
	Godiva: [
		{
			original: GodivaPhoto1,
			thumbnail: GodivaPhoto1,
			description: 'Godiva as a junior kid'
		},
		{
			original: GodivaPhoto2,
			thumbnail: GodivaPhoto2,
			description: 'Godiva as a junior kid'
		},
		{
			original: GodivaPhoto3,
			thumbnail: GodivaPhoto3,
			description: 'Godiva as a senior kid'
		},
		{
			original: GodivaPhoto4,
			thumbnail: GodivaPhoto4,
			description: 'Godiva as a junior kid'
		},
		{
			original: GodivaPhoto5,
			thumbnail: GodivaPhoto5,
			description: 'Godiva as a junior kid'
		},
		{
			original: GodivaPhoto6,
			thumbnail: GodivaPhoto6,
			description: 'Godiva as a junior kid'
		},
		{
			original: GodivaPhoto7,
			thumbnail: GodivaPhoto7,
			description: 'Godiva first freshner rear udder'
		},
		{
			original: GodivaPhoto8,
			thumbnail: GodivaPhoto8,
			description: 'Godiva first freshner side udder'
		}
	],
	Dahlia: [
		{
			original: DahliaPhoto1,
			thumbnail: DahliaPhoto1,
			description: 'Dahlia as a 2nd freshner'
		},
		{
			original: DahliaPhoto2,
			thumbnail: DahliaPhoto2,
			description: 'Dahlia being cute'
		},
		{
			original: DahliaPhoto3,
			thumbnail: DahliaPhoto3,
			description: 'Dahlia 2nd freshening udder'
		},
		{
			original: DahliaPhoto4,
			thumbnail: DahliaPhoto4,
			description: 'Dahlia at 3 years old'
		},
		{
			original: DahliaPhoto5,
			thumbnail: DahliaPhoto5,
			description: 'One of Dahlia\'s 2019 kids'
		},
		{
			original: DahliaPhoto6,
			thumbnail: DahliaPhoto6,
			description: 'Dahlia in the pasture'
		},
		{
			original: DahliaPhoto7,
			thumbnail: DahliaPhoto7,
			description: 'Dahlia dry 2020'
		},
		{
			original: DahliaPhoto8,
			thumbnail: DahliaPhoto8,
			description: 'Dahlia dry 2020'
		},
		{
			original: DahliaPhoto9,
			thumbnail: DahliaPhoto9,
			description: 'Dahlia in 2019'
		},
		{
			original: DahliaPhoto10,
			thumbnail: DahliaPhoto10,
			description: 'Dahlia dry 2020'
		}
	],
	Popcorn: [
		{
			original: PopcornPhoto1,
			thumbnail: PopcornPhoto1,
			description: 'Popcorn as a junior kid'
		},
		{
			original: PopcornPhoto2,
			thumbnail: PopcornPhoto2,
			description: 'Popcorn as a senior kid'
		},
		{
			original: PopcornPhoto3,
			thumbnail: PopcornPhoto3,
			description: 'Popcorn being cute'
		},
		{
			original: PopcornPhoto4,
			thumbnail: PopcornPhoto4,
			description: 'Popcorn first freshening udder'
		},
		{
			original: PopcornPhoto5,
			thumbnail: PopcornPhoto5,
			description: 'Popcorn browsing'
		},
		{
			original: PopcornPhoto6,
			thumbnail: PopcornPhoto6,
			description: 'Popcorn'
		},
		{
			original: PopcornPhoto7,
			thumbnail: PopcornPhoto7,
			description: 'Popcorn loves attention'
		},
		{
			original: PopcornPhoto8,
			thumbnail: PopcornPhoto8,
			description: 'Popcorn hunting for acorns 2020'
		}
	],
	Sloane: [
		{
			original: SloanePhoto1,
			thumbnail: SloanePhoto1,
			description: 'Sloane as a junior kid'
		},
		{
			original: SloanePhoto2,
			thumbnail: SloanePhoto2,
			description: 'Sloane as a first freshner'
		},
		{
			original: SloanePhoto3,
			thumbnail: SloanePhoto3,
			description: 'Sloane browsing 2020'
		},
		{
			original: SloanePhoto4,
			thumbnail: SloanePhoto4,
			description: 'Sloane 2nd freshening udder'
		},
		{
			original: SloanePhoto5,
			thumbnail: SloanePhoto5,
			description: 'Sloane 2nd freshening udder'
		},
		{
			original: SloanePhoto6,
			thumbnail: SloanePhoto6,
			description: 'Sloane 2nd freshening udder'
		},
		{
			original: SloanePhoto7,
			thumbnail: SloanePhoto7,
			description: '2019 doe kid out of Sloane and Eclipse'
		}
	],
	Cyclone: [
		{
			original: CyclonePhoto1,
			thumbnail: CyclonePhoto1,
			description: 'Cyclone as a junior kid'
		},
		{
			original: CyclonePhoto2,
			thumbnail: CyclonePhoto2,
			description: 'Cyclone pretending to be a bunny'
		},
		{
			original: CyclonePhoto3,
			thumbnail: CyclonePhoto3,
			description: 'Cyclone comfy in a chair'
		},
		{
			original: CyclonePhoto4,
			thumbnail: CyclonePhoto4,
			description: 'Cyclone 2020'
		},
		{
			original: CyclonePhoto5,
			thumbnail: CyclonePhoto5,
			description: 'Cyclone 2020'
		},
		{
			original: CyclonePhoto6,
			thumbnail: CyclonePhoto6,
			description: 'Cyclone as a baby kid'
		},
		{
			original: CyclonePhoto7,
			thumbnail: CyclonePhoto7,
			description: ''
		}
	],
	Pretzel: [
		{
			original: PretzelPhoto1,
			thumbnail: PretzelPhoto1,
			description: 'Pretzel as a junior kid'
		},
		{
			original: PretzelPhoto2,
			thumbnail: PretzelPhoto2,
			description: 'Pretzel as a senior kid'
		},
		{
			original: PretzelPhoto3,
			thumbnail: PretzelPhoto3,
			description: 'Pretzel as a dry yearling'
		},
		{
			original: PretzelPhoto4,
			thumbnail: PretzelPhoto4,
			description: 'Pretzel 2020'
		},
		{
			original: PretzelPhoto5,
			thumbnail: PretzelPhoto5,
			description: 'Pretzel first freshner udder'
		},
		{
			original: PretzelPhoto6,
			thumbnail: PretzelPhoto6,
			description: 'Pretzel first freshner udder'
		},
		{
			original: PretzelPhoto7,
			thumbnail: PretzelPhoto7,
			description: 'Pretzel fall 2020'
		},
		{
			original: PretzelPhoto8,
			thumbnail: PretzelPhoto8,
			description: 'Pretzel fall 2020'
		}
	],
	Werthers: [
		{
			original: WerthersPhoto1,
			thumbnail: WerthersPhoto1,
			description: 'Werthers as a junior kid'
		},
		{
			original: WerthersPhoto2,
			thumbnail: WerthersPhoto2,
			description: 'Werthers pregnant 2020'
		},
		{
			original: WerthersPhoto3,
			thumbnail: WerthersPhoto3,
			description: 'Werthers first freshening udder'
		},
		{
			original: WerthersPhoto4,
			thumbnail: WerthersPhoto4,
			description: 'Werthers first freshening udder'
		},
		{
			original: WerthersPhoto5,
			thumbnail: WerthersPhoto5,
			description: 'Werthers first freshner udder'
		},
		{
			original: WerthersPhoto6,
			thumbnail: WerthersPhoto6,
			description: 'Werthers browsing 2020'
		}
	],
	Daiq: [
		{
			original: DaiqPhoto1,
			thumbnail: DaiqPhoto1,
			description: 'Daiquiri as a junior kid'
		},
		{
			original: DaiqPhoto2,
			thumbnail: DaiqPhoto2,
			description: 'Daiquiri summer 2020'
		},
		{
			original: DaiqPhoto3,
			thumbnail: DaiqPhoto3,
			description: 'Daiquiri saying hello to the camera'
		},
		{
			original: DaiqPhoto4,
			thumbnail: DaiqPhoto4,
			description: 'Daiquiri first freshening udder'
		},
		{
			original: DaiqPhoto5,
			thumbnail: DaiqPhoto5,
			description: 'Daiquiri first freshner udder'
		},
		{
			original: DaiqPhoto7,
			thumbnail: DaiqPhoto7,
			description: 'Daiquiri fall 2020'
		}
	],
	Wonton: [
		{
			original: WontonPhoto1,
			thumbnail: WontonPhoto1,
			description: 'Wonton as a junior kid'
		},
		{
			original: WontonPhoto2,
			thumbnail: WontonPhoto2,
			description: 'Wonton as a senior kid'
		},
		{
			original: WontonPhoto3,
			thumbnail: WontonPhoto3,
			description: 'Wonton first freshening udder'
		},
		{
			original: WontonPhoto4,
			thumbnail: WontonPhoto4,
			description: 'Wonton second freshening udder'
		},
		{
			original: WontonPhoto5,
			thumbnail: WontonPhoto5,
			description: 'Wonton fall 2020'
		},
		{
			original: WontonPhoto6,
			thumbnail: WontonPhoto6,
			description: 'Wonton snacking on a pine tree'
		},
		{
			original: WontonPhoto7,
			thumbnail: WontonPhoto7,
			description: 'Wonton 3rd freshening udder'
		},
	],
	Athena: [
		{
			original: AthenaPhoto1,
			thumbnail: AthenaPhoto1,
			description: 'Athena rear udder'
		},
		{
			original: AthenaPhoto2,
			thumbnail: AthenaPhoto2,
			description: 'Athena candid in the paddock'
		},
		{
			original: AthenaPhoto3,
			thumbnail: AthenaPhoto3,
			description: 'Athena rear udder'
		},
		{
			original: AthenaPhoto4,
			thumbnail: AthenaPhoto4,
			description: 'Athena at 9 years old'
		},
	],
	Caddy: [
		{
			original: CaddyPhoto1,
			thumbnail: CaddyPhoto1,
			description: 'Caddy rear udder'
		},
		{
			original: CaddyPhoto2,
			thumbnail: CaddyPhoto2,
			description: 'Caddy at 7 years old'
		},
		{
			original: CaddyPhoto3,
			thumbnail: CaddyPhoto3,
			description: 'Caddy rear udder'
		},
		{
			original: CaddyPhoto4,
			thumbnail: CaddyPhoto4,
			description: 'Caddy at 8 years old'
		},
		{
			original: CaddyPhoto5,
			thumbnail: CaddyPhoto5,
			description: 'Caddy\'s sire, photo credit to the photographer, this is not our photo'
		},
	],
	Dixie: [
		{
			original: DixiePhoto1,
			thumbnail: DixiePhoto1,
			description: 'Dixie rear udder'
		},
		{
			original: DixiePhoto2,
			thumbnail: DixiePhoto2,
			description: 'Dixie at 7 years old'
		},
		{
			original: DixiePhoto3,
			thumbnail: DixiePhoto3,
			description: 'Dixie at 8 years old'
		},
		{
			original: DixiePhoto4,
			thumbnail: DixiePhoto4,
			description: 'Collage of Dixie\'s dam, sire and sire\'s dam'
		},
	],
	Dude: [
		{
			original: DudePhoto1,
			thumbnail: DudePhoto1,
			description: 'Dude as a junior kid, photo credit to End of the Line Farm'
		},
		{
			original: DudePhoto2,
			thumbnail: DudePhoto2,
			description: 'Dude as a junior kid, photo credit to End of the Line Farm'
		},
		{
			original: DudePhoto3,
			thumbnail: DudePhoto3,
			description: 'Dude in the show ring as a young buck, photo credit to End of the Line Farm'
		},
		{
			original: DudePhoto4,
			thumbnail: DudePhoto4,
			description: 'Dude clipped'
		},
		{
			original: DudePhoto5,
			thumbnail: DudePhoto5,
			description: 'Dude\'s dam, photo credit to photographer this is not our photo'
		},
		{
			original: DudePhoto6,
			thumbnail: DudePhoto6,
			description: 'Dude\'s dam, photo credit to photographer this is not our photo'
		},
		{
			original: DudePhoto7,
			thumbnail: DudePhoto7,
			description: 'Dude\'s dam, photo credit to photographer this is not our photo'
		},
		{
			original: DudePhoto8,
			thumbnail: DudePhoto8,
			description: 'Dude\'s grand-dam, photo credit to photographer this is not our photo'
		}
	],
	Zelda: [
		{
			original: ZeldaPhoto1,
			thumbnail: ZeldaPhoto1,
			description: 'Zelda in the paddock'
		},
		{
			original: ZeldaPhoto2,
			thumbnail: ZeldaPhoto2,
			description: 'Zelda candid in the paddock'
		},
		{
			original: ZeldaPhoto3,
			thumbnail: ZeldaPhoto3,
			description: 'Zelda\'s dam'
		},
		{
			original: ZeldaPhoto4,
			thumbnail: ZeldaPhoto4,
			description: 'Zelda\'s sire'
		},
	],
	Powder: [
		{
			original: PowderPhoto1,
			thumbnail: PowderPhoto1,
			description: 'Powder as a junior kid'
		},
		{
			original: PowderPhoto2,
			thumbnail: PowderPhoto2,
			description: 'Powder as a senior kid'
		},
		{
			original: PowderPhoto3,
			thumbnail: PowderPhoto3,
			description: 'Powder as a senior kid'
		},
		{
			original: PowderPhoto4,
			thumbnail: PowderPhoto4,
			description: 'Powder\'s dam Spice'
		},
		{
			original: PowderPhoto5,
			thumbnail: PowderPhoto5,
			description: 'Powder\'s dam\'s first freshening udder'
		},
		{
			original: PowderPhoto6,
			thumbnail: PowderPhoto6,
			description: 'Powder\'s first freshening side udder'
		},
	],
	Evan: [
		{
			original: EvanPhoto1,
			thumbnail: EvanPhoto1,
			description: 'Evan as a junior kid, photo credit to India Blue Farm'
		},
		{
			original: EvanPhoto2,
			thumbnail: EvanPhoto2,
			description: 'Evan as a junior kid, photo credit to India Blue Farm'
		},
		{
			original: EvanPhoto3,
			thumbnail: EvanPhoto3,
			description: 'Evan as a yearling'
		},
		{
			original: EvanPhoto4,
			thumbnail: EvanPhoto4,
			description: 'Evan face shot'
		},
		{
			original: EvanPhoto5,
			thumbnail: EvanPhoto5,
			description: 'Evan rear shot at 3 years old'
		},
		{
			original: EvanPhoto6,
			thumbnail: EvanPhoto6,
			description: 'Evan\'s Dam PROCTOR HILL FARM RT DESTARE 1*M 89 VVVV, photo credit to Proctor Hill Farm'
		},
		{
			original: EvanPhoto7,
			thumbnail: EvanPhoto7,
			description: 'Evan\'s Dam PROCTOR HILL FARM RT DESTARE 1*M 89 VVVV, photo credit to Proctor Hill Farm'
		},
		{
			original: EvanPhoto8,
			thumbnail: EvanPhoto8,
			description: 'Evan\'s Dam PROCTOR HILL FARM RT DESTARE 1*M 89 VVVV, photo credit to Proctor Hill Farm'
		},
		{
			original: EvanPhoto9,
			thumbnail: EvanPhoto9,
			description: 'Evan\'s Sire NC PROMISEDLAND RB BOLERO *B 85 +EV photo credit to Proctor Hill Farm'
		},
		{
			original: EvanPhoto10,
			thumbnail: EvanPhoto10,
			description: 'Evan\'s paternal Grand Dam, photo credit to photographer'
		},
		{
			original: EvanPhoto11,
			thumbnail: EvanPhoto11,
			description: 'Evan\'s paternal Grand Dam, photo credit to photographer'
		},
	],
	Fritz: [
		{
			original: FritzPhoto1,
			thumbnail: FritzPhoto1,
			description: 'Fritz as a junior kid'
		},
		{
			original: FritzPhoto2,
			thumbnail: FritzPhoto2,
			description: 'Fritz as a junior kid'
		},
		{
			original: FritzPhoto3,
			thumbnail: FritzPhoto3,
			description: 'Fritz dam GYPSY MOON DH MAD TEAPARTY 1*M 87 VVVV'
		},
		{
			original: FritzPhoto4,
			thumbnail: FritzPhoto4,
			description: 'Fritz sire WOOLY DOG DOWN ETIENNE BLEU 81 AVV'
		},
		{
			original: FritzPhoto5,
			thumbnail: FritzPhoto5,
			description: 'Fritz at 2 years old'
		},
		{
			original: FritzPhoto6,
			thumbnail: FritzPhoto6,
			description: 'Fritz face shot'
		},
		{
			original: FritzPhoto8,
			thumbnail: FritzPhoto8,
			description: 'Fritz rear shot'
		},
		{
			original: FritzPhoto9,
			thumbnail: FritzPhoto9,
			description: 'Fritz 2 years old'
		},
		{
			original: FritzPhoto10,
			thumbnail: FritzPhoto10,
			description: 'Fritz face shot'
		},
	],
	Mochi: [
		{
			original: MochiPhoto1,
			thumbnail: MochiPhoto1,
			description: 'Mochi rear photo'
		},
		{
			original: MochiPhoto2,
			thumbnail: MochiPhoto2,
			description: 'One of Mochi\'s 2017 daughters as a junior kid'
		},
		{
			original: MochiPhoto3,
			thumbnail: MochiPhoto3,
			description: 'Mochi candid as a yearling'
		},
		{
			original: MochiPhoto4,
			thumbnail: MochiPhoto4,
			description: 'Mochi candid as a yearling'
		},
	],
	Peggy: [
		{
			original: PeggyPhoto1,
			thumbnail: PeggyPhoto1,
			description: 'Peggy as a junior kid recovering from a leg injury she acquired a birth'
		},
		{
			original: PeggyPhoto2,
			thumbnail: PeggyPhoto2,
			description: 'Peggy being silly'
		},
		{
			original: PeggyPhoto3,
			thumbnail: PeggyPhoto3,
			description: 'Peggy fall 2020'
		}
	],
	Ruby: [
		{
			original: RubyPhoto1,
			thumbnail: RubyPhoto1,
			description: 'Ruby Fall 2020'
		},
		{
			original: RubyPhoto2,
			thumbnail: RubyPhoto2,
			description: 'Ruby dry rear fall 2020, never fresh'
		},
	],
	Doublestuff: [
		{
			original: DoublestuffPhoto1,
			thumbnail: DoublestuffPhoto1,
			description: 'Doublestuff as a junior kid'
		},
		{
			original: DoublestuffPhoto2,
			thumbnail: DoublestuffPhoto2,
			description: 'Doublestuff fall 2020'
		},
		{
			original: DoublestuffPhoto3,
			thumbnail: DoublestuffPhoto3,
			description: 'Doublestuff first freshening udder (unclipped) spring 2021'
		},
		{
			original: DoublestuffPhoto4,
			thumbnail: DoublestuffPhoto4,
			description: 'Doublestuff 2nd freshening udder'
		},
	],
	Orzo: [
		{
			original: OrzoPhoto1,
			thumbnail: OrzoPhoto1,
			description: 'Orzo as a junior kid'
		},
		{
			original: OrzoPhoto2,
			thumbnail: OrzoPhoto2,
			description: 'Orzo fall 2020'
		},
		{
			original: OrzoPhoto3,
			thumbnail: OrzoPhoto3,
			description: 'Orzo 2nd freshening udder'
		},
	],
	Mocha: [
		{
			original: MochaPhoto1,
			thumbnail: MochaPhoto1,
			description: 'Mocha as a yearling, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto2,
			thumbnail: MochaPhoto2,
			description: 'Mocha\'s dam rear udder, ELITE doe 2019/2020 and over 1200 lb milker, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto3,
			thumbnail: MochaPhoto3,
			description: 'Mocha\'s dam, ELITE doe 2019/2020 and over 1200 lb milker, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto4,
			thumbnail: MochaPhoto4,
			description: 'Mocha as a yearling, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto5,
			thumbnail: MochaPhoto5,
			description: 'Mocha as a yearling, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto6,
			thumbnail: MochaPhoto6,
			description: 'Mocha\'s dam, ELITE doe 2019/2020 and over 1200 lb milker, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto8,
			thumbnail: MochaPhoto8,
			description: 'Mocha\'s younger sister, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto9,
			thumbnail: MochaPhoto9,
			description: 'Mocha\'s sire, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto10,
			thumbnail: MochaPhoto10,
			description: 'Mocha\'s twin sister who has her junior GRCH (dry) leg, photo credit to Cinnamon Giza at This and That Acres'
		},
		{
			original: MochaPhoto11,
			thumbnail: MochaPhoto11,
			description: 'Mocha, photo credit to Cinnamon Giza at This and That Acres'
		},
	],
	Cleo: [
		{
			original: CleoPhoto1,
			thumbnail: CleoPhoto1,
			description: 'Cleo comfy on the deck'
		},
		{
			original: CleoPhoto2,
			thumbnail: CleoPhoto2,
			description: 'Cleo resting'
		},
		{
			original: CleoPhoto3,
			thumbnail: CleoPhoto3,
			description: 'Cleo walking around'
		},
		{
			original: CleoPhoto4,
			thumbnail: CleoPhoto4,
			description: 'Cleo browsing'
		},
		{
			original: CleoPhoto5,
			thumbnail: CleoPhoto5,
			description: 'Cleo inspecting the camera and looking for some pets'
		},
		{
			original: CleoPhoto6,
			thumbnail: CleoPhoto6,
			description: 'Cleo adventuring with one of our buck kids'
		},
		{
			original: DoublestuffPhoto2,
			thumbnail: DoublestuffPhoto2,
			description: 'Cleo\'s dam Doublestuff dry and fuzzy in the fall of 2020'
		},
		{
			original: DoublestuffPhoto3,
			thumbnail: DoublestuffPhoto3,
			description: 'Cleo\'s dam Doublestuff first freshening udder (unclipped) 1 week fresh'
		},
		{
			original: EclipsePhoto2,
			thumbnail: EclipsePhoto2,
			description: 'Cleo\'s sire Eclipse who appraised VVV 86 as a 2 year old'
		},
		{
			original: CleoPhoto7,
			thumbnail: CleoPhoto7,
			description: 'Cleo\'s dam\'s udder 3 months fresh, 1st freshening'
		},
		{
			original: CleoPhoto8,
			thumbnail: CleoPhoto8,
			description: 'Cleo at 3 months old'
		},
		{
			original: CleoPhoto9,
			thumbnail: CleoPhoto9,
			description: 'Cleo at 2 years old'
		},
		{
			original: CleoPhoto10,
			thumbnail: CleoPhoto10,
			description: 'Cleo\'s udder 5 months fresh, 1st freshening'
		},
	],
	Sophia: [
		{
			original: SophiaPhoto1,
			thumbnail: SophiaPhoto1,
			description: 'Sophia\'s Dam 3rd freshening rear udder'
		},
		{
			original: EclipsePhoto2,
			thumbnail: EclipsePhoto2,
			description: 'Sophia\'s sire Eclipse who appraised VVV 86 as a 2 year old'
		},
		{
			original: DahliaPhoto1,
			thumbnail: DahliaPhoto1,
			description: 'Sophia\'s dam Dahlia as a 2nd freshner'
		},
	],
	Stanley : [
		{
			original: N7Photo1,
			thumbnail: N7Photo1,
			description: 'Stanley\'s Dam 2nd freshening rear udder'
		},
		{
			original: EclipsePhoto2,
			thumbnail: EclipsePhoto2,
			description: 'Stanley\'s sire Eclipse who appraised VVV 86 as a 2 year old'
		},
		{
			original: PeggyPhoto3,
			thumbnail: PeggyPhoto3,
			description: 'Stanley\'s dam Peggy fall 2020'
		}
	],
	LittleGuy: [
		{
			original: N11Photo1,
			thumbnail: N11Photo1,
			description: 'Little Guy loves to be held'
		},
		{
			original: EclipsePhoto2,
			thumbnail: EclipsePhoto2,
			description: 'Little Guy\'s sire Eclipse who appraised VVV 86 as a 2 year old'
		},
		{
			original: DaiqPhoto4,
			thumbnail: DaiqPhoto4,
			description: 'Little Guy\'s dam Daiquiri first freshening udder'
		},
		{
			original: DaiqPhoto7,
			thumbnail: DaiqPhoto7,
			description: 'Little Guy\'s dam Daiquiri fall 2020'
		},
		{
			original: N11Photo2,
			thumbnail: N11Photo2,
			description: 'Little Guy\'s dam milked out right after clipping summer 2021'
		},
	],
	LittleGold: [
		{
			original: N12Photo1,
			thumbnail: N12Photo1,
			description: 'Little Gold is such a sweetie'
		},
		{
			original: EclipsePhoto2,
			thumbnail: EclipsePhoto2,
			description: 'Little Gold\'s sire Eclipse who appraised VVV 86 as a 2 year old'
		},
		{
			original: DaiqPhoto4,
			thumbnail: DaiqPhoto4,
			description: 'Little Gold\'s dam Daiquiri first freshening udder'
		},
		{
			original: DaiqPhoto7,
			thumbnail: DaiqPhoto7,
			description: 'Little Gold\'s dam Daiquiri fall 2020'
		},
		{
			original: N11Photo2,
			thumbnail: N11Photo2,
			description: 'Little Gold\'s dam milked out right after clipping summer 2021'
		},
	],
	Spicey: [
		{
			original: SpiceyPhoto1,
			thumbnail: SpiceyPhoto1,
			description: 'Spicey\'s dam GCH RAINEY VALLEY SGW CINNAMON 2*M VEEE91 photo credit to Til-Riv Farm'
		},
		{
			original: SpiceyPhoto2,
			thumbnail: SpiceyPhoto2,
			description: 'Spicey\'s dam GCH RAINEY VALLEY SGW CINNAMON 2*M VEEE91 photo credit to Til-Riv Farm'
		},
		{
			original: SpiceyPhoto3,
			thumbnail: SpiceyPhoto3,
			description: 'Spicey\'s dam GCH RAINEY VALLEY SGW CINNAMON 2*M VEEE91 photo credit to Til-Riv Farm'
		},
		{
			original: SpiceyPhoto4,
			thumbnail: SpiceyPhoto4,
			description: 'Spicey\'s sire STAYAHILE FIN CAMERA TECH *B photo credit to Til-Riv Farm'
		},
	],
	Twix: [
		{
			original: TwixPhoto1,
			thumbnail: TwixPhoto1,
			description: 'Twix\'s udder at 5 months fresh, 1st freshening'
		},
		{
			original: TwixPhoto2,
			thumbnail: TwixPhoto2,
			description: 'Twix at 2 months old'
		},
		{
			original: TwixPhoto3,
			thumbnail: TwixPhoto3,
			description: 'Twix at 2 years old'
		},
		{
			original: RomeoMainPhoto,
			thumbnail: RomeoMainPhoto,
			description: 'Twix\'s sire, photo credit to Kyeema Ridge'
		},
		{
			original: PretzelPhoto8,
			thumbnail: PretzelPhoto8,
			description: 'Twix\'s dam'
		},
		{
			original: PretzelPhoto5,
			thumbnail: PretzelPhoto5,
			description: 'Twix\'s dam\'s udder'
		}
	],
	Vanta: [
		{
			original: VantaPhoto1,
			thumbnail: VantaPhoto1,
			description: 'Vanta at 1 month old'
		},
		{
			original: VantaPhoto2,
			thumbnail: VantaPhoto2,
			description: 'Vanta at 1 year old'
		},
		{
			original: MochaMain,
			thumbnail: MochaMain,
			description: 'Vanta\'s sire This & That Acres Mocha *B'
		},
		{
			original: MochaPhoto2,
			thumbnail: MochaPhoto2,
			description: 'Vanta\'s grand-dam udder photo credit to This & That Acres'
		},
		{
			original: DaiqPhoto7,
			thumbnail: DaiqPhoto7,
			description: 'Vanta\'s dam'
		},
		{
			original: DaiqPhoto4,
			thumbnail: DaiqPhoto4,
			description: 'Vanta\'s dam\'s udder'
		}
	],
	Extra: [
		{
			original: ExtraPhoto1,
			thumbnail: ExtraPhoto1,
			description: 'Extra at 1 month old'
		},
		{
			original: MochaMain,
			thumbnail: MochaMain,
			description: 'Extra\'s sire This & That Acres Mocha *B'
		},
		{
			original: MochaPhoto2,
			thumbnail: MochaPhoto2,
			description: 'Extra\'s grand-dam udder photo credit to This & That Acres'
		},
		{
			original: DulcePhoto4,
			thumbnail: DulcePhoto4,
			description: 'Extra\'s dam'
		},
		{
			original: DulcePhoto7,
			thumbnail: DulcePhoto7,
			description: 'Extra\'s dam\'s udder'
		}
	]
};
