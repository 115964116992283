import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MainPhotos from './Images/MainPhotos.js';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	homeContent: {
		textAlign: 'center',
		whiteSpace: 'pre-line',
		maxWidth: '1000px'
	},
	mainImage: {
		maxWidth: '800px'
	},
	mobileImages: {
		maxWidth: '350px'
	}
}));

export default function RabbitsContent(props) {
	const {isNotMobile} = props;
	const classes = useStyles();
	return(
		<div className={classes.homeContent}>
			<img src={MainPhotos.Convention} alt="Imagination's Indianna our 2017 BOB Convention winner" className={isNotMobile ? classes.mainImage : classes.mobileImages}/>
			<Typography>
			{'We have raised a variety of purebred rabbit breeds on the farm including English Angora, Belgian Hare, Rhinelander, French Lop and Creme D\'Argent. We were fortunate to do well showing with these breeds at ARBA sanctioned shows and are very proud of our accomplishments in these breeds including some Convention level BOB and BOS wins. We no longer actively raise any of these breeds. If you\'re looking for a rabbit from one of these breeds we strongly suggest getting in touch with the National club for the breed, they should be able to refer you to breeders who can help you with getting stock. All national clubs are listed on the ARBA website '}<a href="https://arba.net/national-specialty-clubs/">here</a>.{'  \n \n We offer ARBA registration services to all ARBA members within a reasonable driving distance of our farm. We will come to you or can meet you somewhere to register your rabbits. Please see the '}<a href="https://arba.net/for-registrars/">ARBA website</a>{' to find out in detail about all the requirements for registration. The basics include an active ARBA membership card, complete 3 generation pedigree for your rabbit, and a rabbit that is at least 6 months old and meets all of its senior breed requirements without any disqualifications. Feel free to email the farm to inquire about our ARBA registration services and to setup an appointment. \n \n In addition to ARBA registration services we can also offer judging services for ARBA licensed rabbit shows along with 4H and fair level rabbit shows. (ARBA Judge #1041) Feel free to email the farm if you are interested in having us judge your rabbit show.'}
			</Typography>
			<img src={MainPhotos.CRBIS} alt="Imagination's C42 Best in Show win" className={isNotMobile ? classes.mainImage : classes.mobileImages}/>
		</div>
	);
};

RabbitsContent.propTypes = {
	isNotMobile: PropTypes.bool.isRequired
};
