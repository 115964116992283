import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GoatGallery from './GoatGallery';
import GoatVideos from './GoatVideos';

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '800px',
		margin: '1rem',
	},
	cards: {
		display: 'grid',
		justifyItems: 'center'
	},
	 expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardActions: {
	  justifyContent: 'flex-end'
  },
  main: {
	  maxWidth: '800px'
  },
  mainMobile: {
	  maxWidth: '300px'
  }
}));
	
export default function ExpandedGoat(props) {
	const classes = useStyles();
	const { nickName, description, isNotMobile, expandedSales} = props;
	const [expanded, setExpanded] = useState(expandedSales);

	const handleExpandClick = () => {
	setExpanded(!expanded);
	};			
	return(
			<>
				<CardActions className={classes.cardActions}>
					{ !expanded && 
						<Typography>
							{`Click the icon on the right to see more about ${nickName}`}
						</Typography>
					}
					<IconButton
						className={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</IconButton>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent className={isNotMobile ?  classes.main : classes.mainMobile }>
						{description && <><div dangerouslySetInnerHTML={{__html: description}}></div><br/></>}
						{nickName && <GoatGallery nickName={nickName}/>}
						{nickName && <GoatVideos nickName={nickName}/>}
					</CardContent>
				</Collapse>
			</>
		);
			  
	};
	
	ExpandedGoat.propTypes = {
		nickName: PropTypes.string,
		description: PropTypes.string,
		isNotMobile: PropTypes.bool.isRequired,
		expandedSales: PropTypes.bool
	};