import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	homeContent: {
		textAlign: 'center',
		whiteSpace: 'pre-line',
		maxWidth: '1000px'
	}
}));

export default function BucksOnIceContent() {
	const classes = useStyles();
	return(
		<div className={classes.homeContent}>
			<Typography>
			{'Listed here are the wonderful bucks we have been fortunate enough to add to our semen tank. All photos on this page are credited to the owners of the bucks and more information about each of them can be found on their owner/breeder websites which are linked to in their expanded content section below. We use AI heavily in our herd whenever possible to enhance our genetics without having to bring in a live buck. We are very grateful to the buck owners for collecting their herdsires and giving the opportunity for others like us to benefit from their hardwork. Bucks on ice are listed in age order from oldest to youngest. At this time none of the bucks below have straws for sale from our tank.'}
			</Typography>
		</div>
	);
};