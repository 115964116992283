import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Breedings2023 from './Breedings2023.js';
import MainPhotos from './Images/MainPhotos.js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import ExpandedGoatBreeding from './ExpandedGoatBreeding';
//import { HashLink as Link } from 'react-router-hash-link';
import Check from '@material-ui/icons/Check';
import Kids2023 from './Images/Kids2023.js';
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
	kiddingSchedule: {
		textAlign: 'center',
		maxWidth: '1000px'
	},
	newLine: {
	whiteSpace: 'pre-line'
	},
	breedingCard: {
		marginBottom: '1rem'
	},
	breedingGrid: {
		display: 'grid',
		gridTemplateColumns: '2fr 2fr 2fr',
		alignItems: 'flex-start'		
	},
	secondGrid: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		alignItems: 'flex-start'
	},
	images: {
		maxWidth: '350px',
		maxHeight: '300px',
		padding: '5px'
	},
	mainPhoto: {
		maxWidth: '800px'
	},
	mobileMainPhoto: {
		maxWidth: '290px'
	},
	pedigree: {
		maxWidth: '800px'
	},
	mobilePedigree: {
		maxWidth: '290px'
	},
	check: {
		color: 'green',
	},
	kids: {
		display: 'flex',
		justifyContent: 'space-evenly'
	}
}));

const sortBreedings = (a, b) => {
	return new Date (a.Date) - new Date (b.Date);	
};

export default function KiddingSchedule(props) {
	const {isNotMobile} = props;
	const classes = useStyles();
	return(
		<>
			<div className={classes.kiddingSchedule}>
				<img src={MainPhotos.Kiddings} alt="Kidding" className={ isNotMobile ? classes.mainPhoto : classes.mobileMainPhoto}/>
				<Typography>
				{'Below are the kidding plans for 2023. '}
				</Typography>
				<br/>
				<Typography className={classes.newLine}>
				{
					'We have an extremely limited kidding season this year with only 3 does who kidded in March. The sire to all kids this year is ONLY AGS registered and because of ADGA\'s inability to register AGS animals at this time (and with no realistic time frame for when this service will be available) any kids sold with registration papers this year will ONLY be registered with AGS. NOTE: All kids have been sold.'
				}
				</Typography>
				<br/>
				{!isNotMobile && 
					<>
						<Typography>
							{'Note: This page is best viewed in desktop versus mobile display'}
						</Typography>
						<br/>
					</>
				}
				<Typography variant="h5">
				{'Breeding Schedule 2023:'}
				</Typography>
				<br/>
			</div>{
				isNotMobile ? 
				Breedings2023.Breedings.sort(sortBreedings).map((b) => (
				<Card variant="outlined" key={b.Dam} className={classes.breedingCard}>
					<CardContent>
						<div className={classes.breedingGrid}>
							<div>
								<Typography><strong>{'Buck:'}</strong></Typography>
									{ b.Frozen &&
										<Link to={`/bucksonice#${b.SireNickname}`}>
											<Typography>
												{b.Sire}
											</Typography>
										</Link>
									}
									{b.Reference &&
										<Link to={`/reference#${b.SireNickname}`}>
											<Typography>
												{b.Sire}
											</Typography>
										</Link>
									}
									{!b.Frozen && !b.Reference &&
										<Link to={`/bucks#${b.SireNickname}`}>
											<Typography>
												{b.Sire}
											</Typography>
										</Link>
									}
								{MainPhotos[b.SireNickname] ? <img className={classes.images} src={MainPhotos[b.SireNickname]} alt={b.Sire}/> : <Typography>photo coming soon!</Typography>}
								{
									b.PHOTO_CREDIT &&
									<Typography variant="body2">
										{b.PHOTO_CREDIT}
									</Typography>
								}
							</div>
							<div>
								<Typography><strong>{'Doe:'}</strong></Typography>
								{b.DoeReference && 
									<Link to={`/reference#${b.DamNickname}`}>
										<Typography>
											{b.Dam}
										</Typography>
									</Link>
								}
								{!b.DoeReference &&
									<Link to={`/does#${b.DamNickname}`}>
										<Typography>
											{b.Dam}
										</Typography>
									</Link>
								}
								{MainPhotos[b.DamNickname] && <img className={classes.images} src={MainPhotos[b.DamNickname]} alt={b.Dam}/>}
							</div>
							<div>
								<Typography><strong>{'Planned Pedigree:'}</strong></Typography>
								{b.Pedigree && 
									/*<img className={classes.pedigree} src={b.Pedigree} alt="ADGA Genetics pedigree for this breeding"/>*/
									<iframe 
										src={`https://adgagenetics.org/PlannedPedigreePrint.aspx?SireNum=${b.SireReg.replace('D', 'D00')}&DamNum=${b.DamReg.replace('D', 'D00')}`} 
										height="400px" 
										width="700px" 
										style={{border: 'none'}}
										title={`ADGA Genetics Pedigree for ${b.DamNickname} and ${b.SireNickname}`}
									/>
								}
							</div>
						</div>
						{ !b.Born &&
							<div className={classes.secondGrid}>
								<div><Typography><strong>{'Breeding Date:'}</strong></Typography><Typography>{b.Breeding}</Typography></div>
								<div><Typography><strong>{'Breeding Confirmed:'}</strong></Typography>{b.Bred && <Check className={classes.check}/>}<Typography>{b.Confirmed}</Typography></div>
								<div><Typography><strong>{'Due Date:'}</strong></Typography><Typography>{b.Due}</Typography></div>
							</div>
						}
						{
							b.Born &&
								<div>
									<Typography><strong>{'Kidding Results:'}</strong></Typography>
									<Typography>{`Born on ${b.Kidded}`}</Typography>
									<Typography>{b.Offspring}</Typography>
									<div className={classes.kids}>
									{
										Kids2023[b.DamNickname] && Kids2023[b.DamNickname].map((k) => (
											<div key={k.Photo}>
												<img className={classes.images} src={k.Photo} alt={k.Description}></img>
												<Typography>{k.Description}</Typography>
											</div>
										))
									}
									</div>
								</div>
						}
					</CardContent>
				</Card>
			))
			: 
				Breedings2023.Breedings.sort(sortBreedings).map((b) => (
				<Card variant="outlined" key={b.Dam} className={classes.breedingCard}>
					<CardContent>
						<div>
							<Typography>
								{`Buck:`}
							</Typography>
							{ b.Frozen && 
								<Link to={`/bucksonice#${b.SireNickname}`}>
									<Typography>
										{b.Sire}
									</Typography>
								</Link>
							}
							{ b.Reference && 
								<Link to={`/reference#${b.SireNickname}`}>
									<Typography>
										{b.Sire}
									</Typography>
								</Link>
							}
							{ !b.Frozen && !b.Reference && 
								<Link to={`/bucks#${b.SireNickname}`}>
									<Typography>
										{b.Sire}
									</Typography>
								</Link>
							}
							{MainPhotos[b.SireNickname] ? <img className={classes.mobileMainPhoto} src={MainPhotos[b.SireNickname]} alt={b.Sire}/> : <Typography>photo coming soon!</Typography>}
						</div>
						<div>
							<Typography>
								{`Doe:`}
							</Typography>
							{b.DoeReference && 
								<Link to={{
									pathname: "/reference",
									hash: b.DamNickname
								}}>
									<Typography>
										{b.Dam}
									</Typography>
								</Link>
							}
							{!b.DoeReference &&
								<Link to={{
									pathname: "/does",
									hash: b.DamNickname
								}}>
									<Typography>
										{b.Dam}
									</Typography>
								</Link>
							}
							{MainPhotos[b.DamNickname] ? <img className={classes.mobileMainPhoto} src={MainPhotos[b.DamNickname]} alt={b.Dam}/> : <Typography>photo coming soon!</Typography>}
						</div>
					</CardContent>
					<ExpandedGoatBreeding kidList={Kids2023} confirmed={b.Confirmed} breedingDate={b.Breeding} dueDate={b.Due} born={b.Born} kidded={b.Kidded} offspring={b.Offspring} damNickname={b.DamNickname}/>
				</Card>
			))
			}
		</>
	);
};

KiddingSchedule.propTypes = {
	isNotMobile: PropTypes.bool.isRequired
};
