import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '800px',
		margin: '1rem',
	},
	cards: {
		display: 'grid',
		justifyItems: 'center'
	},
	 expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardActions: {
	  justifyContent: 'space-between'
  },
  mainMobile: {
	  maxWidth: '350px'
  },
  	images: {
		maxWidth: '350px',
		maxHeight: '300px',
		padding: '5px'
	},
}));
	
export default function ExpandedGoatBreeding(props) {
	const classes = useStyles();
	const { breedingDate, dueDate, kidded, confirmed, offspring, born, damNickname, kidList } = props;
	const [expanded, setExpanded] = useState(true);

	const handleExpandClick = () => {
	setExpanded(!expanded);
	};			
	return(
			<>
				<CardActions className={classes.cardActions}>
					{ !expanded && 
						<Typography variant="body2">
							{`Click the icon on the right to see more about this breeding`}
						</Typography>
					}
					<IconButton
						className={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</IconButton>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent className={classes.mainMobile}>
						{!born &&
							<>						
								<div>
									<Typography variant="body2"><strong>{`Breeding Date:`}</strong></Typography>
									<Typography variant="body2">{breedingDate}</Typography>
								</div>
								<div>
									<Typography variant="body2"><strong>{`Confirmed:`}</strong></Typography>
									<Typography variant="body2">{confirmed}</Typography>
								</div>
								<div>
									<Typography variant="body2"><strong>{`Due Date:`}</strong></Typography>
									<Typography variant="body2">{dueDate}</Typography>
								</div>
							</>
						}
						{born && 
							<div>
								<Typography variant="body2"><strong>{`Kidding Results:`}</strong></Typography>
								<Typography variant="body2">{`Born on ${kidded}: ${offspring}`}</Typography>
												{
										kidList[damNickname] && kidList[damNickname].map((k) => (
											<div key={k.Photo}>
												<img className={classes.images} src={k.Photo} alt={k.Description}></img>
												<Typography>{k.Description}</Typography>
											</div>
										))
									}
							</div>				
						}
					</CardContent>
				</Collapse>
			</>
		);
			  
	};
	
	ExpandedGoatBreeding.propTypes = {
		breedingDate: PropTypes.string.isRequired,
		dueDate: PropTypes.string.isRequired,
		kidded: PropTypes.string.isRequired,
		confirmed: PropTypes.string.isRequired,
		offspring: PropTypes.string,
		born: PropTypes.bool,
		damNickname: PropTypes.string,
		kidList: PropTypes.object.isRequired
	};
