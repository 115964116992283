import WerthersMainPhoto from './Werthers/Main.jpg';
import EclipseMainPhoto from './Eclipse/Main.jpg';
import ZeldaMainPhoto from './Reference/ZeldaMain.jpg';
import PowderMainPhoto from './Reference/PowderMain.jpg';
import EvanMainPhoto from './Reference/EvanMain.jpg';
import MochiMainPhoto from './Reference/MochiMain.jpg';
import CaddyMainPhoto from './Reference/CaddyMain.jpg';
import AthenaMainPhoto from './Reference/AthenaMain.jpg';
import DixieMainPhoto from './Reference/DixieMain.jpg';
import DudeMainPhoto from './Reference/DudeMain.jpg';
import RobbieMainPhoto from './BucksOnIce/RobbieMain.jpg';
import BaylinerMainPhoto from './BucksOnIce/BaylinerMain.jpg';
import GalaxyMainPhoto from './BucksOnIce/GalaxyMain.jpg';
import GlacierMainPhoto from './BucksOnIce/GlacierMain.jpg';
import CupidMainPhoto from './Cupid/CupidMain.jpg';
import SunnyMainPhoto from './Sunny/SunnyMain.jpg';
import DudetteMainPhoto from './Dudette/DudetteMain.jpg';
import DulceMainPhoto from './Dulce/DulceMain.jpg';
import GodivaMainPhoto from './Godiva/GodivaMain.jpg';
import RainstormMainPhoto from './Rainstorm/RainstormMain.jpg';
import CloudMainPhoto from './Cloud/CloudMain.jpg';
import RhubarbMainPhoto from './Rhubarb/RhubarbMain.jpg';
import FritzMainPhoto from './Reference/FritzMain.jpg';
import DahliaMainPhoto from './Dahlia/DahliaMain.JPG';
import PopcornMainPhoto from './Popcorn/PopcornMain.jpg';
import SloaneMainPhoto from './Sloane/SloaneMain.jpg';
import CycloneMainPhoto from './Cyclone/CycloneMain.jpg';
import PretzelMainPhoto from './Pretzel/PretzelMain.JPG';
import DaiqMainPhoto from './Daiquiri/DaiqMain.JPG';
import WontonMainPhoto from './Wonton/WontonMain.jpg';
import OrzoMainPhoto from './Orzo/OrzoMain.jpg';
import DoublestuffMainPhoto from './Doublestuff/DoublestuffMain.jpg';
import PeggyMainPhoto from './Peggy/PeggyMain.jpg';
import RubyMainPhoto from './Ruby/RubyMain.jpg';
import HomePagePhoto from './HomePage.jpg';
import KiddingPhoto from './Kiddings.jpg';
import ConventionWin from './Rabbits/convention.jpg';
import CremeBIS from './Rabbits/CRBIS.jpg';
import TwoStepMainPhoto from './BucksOnIce/TwoStep.jpg';
import DerringerMainPhoto from './BucksOnIce/Derringer.jpg';
import BugattiMainPhoto from './BucksOnIce/Bugatti.jpg';
import WarPartyMainPhoto from './BucksOnIce/WarParty.jpg';
import CaboMainPhoto from './BucksOnIce/Cabo.jpg';
import RomeoMainPhoto from './BucksOnIce/Romeo.jpg';
import MochaMainPhoto from './Mocha/MochaMain.jpg';
import CleoMainPhoto from './Cleo/CleoMain.jpg';
import BlancheMainPhoto from './Blanche/Main.jpg';
import RoseMainPhoto from './Rose/Main.jpg';
import TwixMainPhoto from './Twix/Main.jpg';
import StanleyMainPhoto from './N7/StanleyMain.jpg';
import N11MainPhoto from './N11/Main.jpg';
import N12MainPhoto from './N12/Main.jpg';
import SophiaMainPhoto from './Sophia/Main.jpg';
import SpiceyMainPhoto from './BucksOnIce/Spicey.jpg';
import ExtraMainPhoto from './Extra/ExtraMain.jpg';
import VantaMainPhoto from './Vanta/VantaMain.jpg';
import NoxMainPhoto from './Nox/NoxMain.jpg';

export default {
    Werthers: WerthersMainPhoto,
	Eclipse: EclipseMainPhoto,
	Zelda: ZeldaMainPhoto,
	Powder: PowderMainPhoto,
	Evan: EvanMainPhoto,
	Mochi: MochiMainPhoto,
	Caddy: CaddyMainPhoto,
	Athena: AthenaMainPhoto,
	Dixie: DixieMainPhoto,
	Dude: DudeMainPhoto,
	Robbie: RobbieMainPhoto,
	Bayliner: BaylinerMainPhoto,
	Galaxy: GalaxyMainPhoto,
	Glacier: GlacierMainPhoto,
	Cupid: CupidMainPhoto,
	Sunny: SunnyMainPhoto,
	Dudette: DudetteMainPhoto,
	Godiva: GodivaMainPhoto,
	Rainstorm: RainstormMainPhoto,
	Cloud: CloudMainPhoto,
	Rhubarb: RhubarbMainPhoto,
	Dulce: DulceMainPhoto,
	Fritz: FritzMainPhoto,
	Dahlia: DahliaMainPhoto,
	Popcorn: PopcornMainPhoto,
	Sloane: SloaneMainPhoto,
	Cyclone: CycloneMainPhoto,
	Pretzel: PretzelMainPhoto,
	Daiq: DaiqMainPhoto,
	Wonton: WontonMainPhoto,
	Doublestuff: DoublestuffMainPhoto,
	Orzo: OrzoMainPhoto,
	Ruby: RubyMainPhoto,
	Peggy: PeggyMainPhoto,
	Home: HomePagePhoto,
	Kiddings: KiddingPhoto,
	Convention: ConventionWin,
	CRBIS: CremeBIS,
	TwoStep: TwoStepMainPhoto,
	Derringer: DerringerMainPhoto,
	Cabo: CaboMainPhoto,
	Romeo: RomeoMainPhoto,
	Bugatti: BugattiMainPhoto,
	WarParty: WarPartyMainPhoto,
	Mocha: MochaMainPhoto,
	Cleo: CleoMainPhoto,
	Rose: RoseMainPhoto,
	Blanche: BlancheMainPhoto,
	Twix: TwixMainPhoto,
	Stanley: StanleyMainPhoto,
	Sophia: SophiaMainPhoto,
	LittleGold: N12MainPhoto,
	LittleGuy: N11MainPhoto,
	Spicey: SpiceyMainPhoto,
	Extra: ExtraMainPhoto,
	Vanta: VantaMainPhoto,
	Nox: NoxMainPhoto
	
};
