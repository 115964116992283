import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import GoatList from './GoatList.js';
import PropTypes from 'prop-types';
import ExpandedGoat from './ExpandedGoat';
import MainPhotos from './Images/MainPhotos.js';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '1000px',
		margin: '1rem',
	},
	cards: {
		display: 'grid',
		justifyItems: 'center'
	},
	mainImage: {
		maxWidth: '800px'
	},
	mainImageMobile: {
		maxWidth: '300px'
	},
	newLine: {
		whiteSpace: 'pre-line'
	}
}));

const sortGoatsByAge = (a, b) => {
	return new Date (a.DOB) - new Date (b.DOB);	
};

export default function GoatProfiles(props) {
  const {group, sales, filterBy, isNotMobile} = props;
  const classes = useStyles();
  const { hash } = useLocation();

  useEffect(() => {
  	if(hash) {
  		const element = document.getElementById(hash.substring(1));
			element && element.scrollIntoView();
  	}
  },[hash]);
  
  const filterReferenceGoats = (value) => {
	  if(group === 'REFERENCE'){
		  if (filterBy) {
			  return value.SEX === filterBy;
		  }
		  else {
			  return value;
		  }
	  }
	  else return value;
  };
  
  return (
    <div className={classes.cards}>
		{ GoatList.Goats.sort(sortGoatsByAge).filter(filterReferenceGoats).map((goat) => {
			if ( (!sales) && (goat.GOAT_SEX === group) && (!goat.FOR_SALE) ) return (
			<div id={`${goat.NICKNAME}`} key={goat.NICKNAME}>			
				<Card className={classes.card}>
					<CardHeader className={classes.newLine} title={goat.GOAT_SEX !== 'FROZEN' && goat.NICKNAME ? `${goat.GOAT_NAME} ${goat.STARS} ${goat.DNA} \n aka "${goat.NICKNAME}"` : `${goat.GOAT_NAME}  ${goat.STARS} ${goat.DNA}`}>
					</CardHeader>
					<CardContent>
						{goat.NICKNAME && MainPhotos[goat.NICKNAME] ?
							<img src={MainPhotos[goat.NICKNAME]} alt={goat.NICKNAME} className={isNotMobile ?  classes.mainImage : classes.mainImageMobile }/>
							: 
							<><Typography variant="caption">
								{'Photo coming soon!'}
							</Typography><br/><br/></>
						}
						{
							goat.NICKNAME && goat.PHOTO_CREDIT &&
							<>
								<br/>
								<Typography variant="caption">
								{goat.PHOTO_CREDIT}
								</Typography>
							</>
						}
						<Typography>
							{`Date of Birth: ${goat.DATE_OF_BIRTH}`}
						</Typography>
						{goat.LA &&
							<Typography>
								{`Linear Appraisal Age/Score: ${goat.LA}`}
							</Typography>
						}
						{goat.SHOW_WINS && goat.SHOW_WINS.length &&
							goat.SHOW_WINS.map((show, i) => (
								<Typography key={i}>
								{`Show Wins: ${show}`}
								</Typography>
							))
							
						}
						<br/>
						<Typography>
							{`Sire: ${goat.SIRE}`}
						</Typography>
						<Typography>
							{`Dam: ${goat.DAM}`}
						</Typography>
						{
							goat.GOAT_PEDIGREE && <a target="_blank" rel="noopener noreferrer" href={`https://adgagenetics.org/GoatDetail.aspx?RegNumber=${goat.OLD_REG_NUM.replace('D', 'D00')}`} >ADGA Genetics Pedigree Link</a>
						}
					</CardContent>
					<ExpandedGoat goatName={goat.GOAT_NAME} nickName={goat.NICKNAME} description={goat.DESCRIPTION} isNotMobile={isNotMobile}/>
				</Card>
			</div>				
			);
		if (sales && goat.FOR_SALE === true) return (
			<Card key={goat.ID} className={classes.card}>
				<CardHeader className={classes.newLine} title={goat.GOAT_SEX !== 'FROZEN' && goat.NICKNAME ? `${goat.GOAT_NAME} ${goat.STARS} ${goat.DNA} \n aka "${goat.NICKNAME}"` : `${goat.GOAT_NAME}  ${goat.STARS} ${goat.DNA}`}>
				</CardHeader>
				<CardContent>
					{goat.NICKNAME && MainPhotos[goat.NICKNAME] ?
						<img src={MainPhotos[goat.NICKNAME]} alt={goat.NICKNAME} className={isNotMobile ?  classes.mainImage : classes.mainImageMobile }/>
						: 
						<><Typography variant="caption">
							{'Photo coming soon!'}
						</Typography><br/><br/></>
					}
					<Typography>
						{`Sex: ${goat.GOAT_SEX}`}
					</Typography>
					<Typography>
						{`Date of Birth: ${goat.DATE_OF_BIRTH}`}
					</Typography>
					{goat.LA &&
						<Typography>
							{`Linear Appraisal Age/Score: ${goat.LA}`}
						</Typography>
					}
					<br/>
					<Typography>
						{`Sire: ${goat.SIRE}`}
					</Typography>
					<Typography>
						{`Dam: ${goat.DAM}`}
					</Typography>
					{
						goat.GOAT_PEDIGREE && <a target="_blank"  rel="noopener noreferrer" href={`https://adgagenetics.org/GoatDetail.aspx?RegNumber=${goat.OLD_REG_NUM.replace('D', 'D00')}`} >ADGA Genetics Pedigree Link</a>
					}
					<br/><br/>
					<Typography variant="h6">
					{
						`Price: ${goat.PRICE}`
					}
					</Typography>
				</CardContent>
				<ExpandedGoat goatName={goat.GOAT_NAME} nickName={goat.NICKNAME} isNotMobile={isNotMobile} description={goat.DESCRIPTION} expandedSales={true}/>
			</Card>	
		);			
		})
		}
    </div>
  );
}

GoatProfiles.propTypes = {
	group: PropTypes.string.isRequired,
	sales: PropTypes.bool.isRequired,
	filterBy: PropTypes.string,
	isNotMobile: PropTypes.bool.isRequired
};
