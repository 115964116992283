import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	homeContent: {
		textAlign: 'center',
		whiteSpace: 'pre-line',
		maxWidth: '800px',
		padding: '1rem'
	}
}));

export default function HomeContent() {
	const classes = useStyles();
	return(
		<div className={classes.homeContent}>
			<Typography>
			{'Welcome to Imagination Acres Farm. \n We are a 20 acre farm in Spotsylvania Virginia. \n \n We have an ARBA registered rabbitry raising purebred rabbits. Our rabbitry is owned and operated by ARBA Judge #1041. \n \n For over a decade and half we raised a small herd of ADGA registered Nigerian Dwarf Goats. As of 2024 our remaining herd is retired and we are no longer breeding goats. We have kept the goat page up for reference purposes.'  }
			</Typography>
		</div>
	);
};
