//Dahlia
import DahliaVideo1 from './Dahlia/Video1.mp4';
import DahliaVideo2 from './Dahlia/Video2.mp4';
//Rhubarb
//import RhubarbVideo1 from './Rhubarb/Video1.mp4';
//import RhubarbVideo2 from './Rhubarb/Video2.mp4';
//Cyclone
import CycloneVideo1 from './Cyclone/Video1.mp4';

export default {
	Dahlia: [
		{
			video: DahliaVideo1,
			description: 'Dahlia being pet'
		},
		{
			video: DahliaVideo2,
			description: 'Dahlia being hand milked as a 2nd freshner'
		}
	],
	/*Rhubarb: [
		{
			video: RhubarbVideo1,
			description: 'Rhubarb being machine milked'
		},
		{
			video: RhubarbVideo2,
			description: 'Rhubarb being hand milked'
		}
	],*/
	Cyclone: [
		{
			video: CycloneVideo1,
			description: 'Cyclone being pet'
		}
	]
};