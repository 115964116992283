import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Constants from './Constants';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";

export default function MainMenu(props) {
	const { pageMessages, pageArray} = Constants;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);


	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};


	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Tooltip title="Main Menu">
				<IconButton
					aria-label="Main Menu"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleMenu}
					color="inherit"
				>
					<MenuIcon />
				</IconButton>
			</Tooltip>
			<Menu
				id="menu-appbar"
				getContentAnchorEl={null}
				anchorEl={anchorEl}
					anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={open}
				onClose={handleClose}
			>
			{
				pageArray.map((page, i) => (			  	  
					<MenuItem onClick={handleClose} key={pageArray[i]} to={`/${pageArray[i]}`} component={Link}>{pageMessages[page]}</MenuItem>
				))
			}
			</Menu>
		</div>
	);
}
