import React, {useState, useCallback} from 'react';
import './App.css';
import HeaderBar from './HeaderBar';
import Footer from './Footer';
import GoatProfiles from './GoatProfiles';
import SalesProfiles from './SalesProfiles';
import { makeStyles } from '@material-ui/core/styles';
import Constants from './Constants.js';
import HomeContent from './HomeContent';
import SalesPolicies from './SalesPolicies';
import ReferenceContent from './ReferenceContent';
import KiddingSchedule2023 from './KiddingSchedule2023';
import BucksOnIceContent from './BucksOnIce';
import BucksContent from './Bucks';
import DoesContent from './Does';
import MainPhotos from './Images/MainPhotos.js';
import RabbitsContent from './RabbitsContent';
import ScrollToTop from './ScrollToTop';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '800px',
  },
  logoMobile: {
    maxWidth: '300px',
  },
  main: {
	  padding: '1rem',
	  textAlign: '-webkit-center'
  },
}));

function App() {
	const {groups} = Constants; 
	const classes = useStyles();	
	const [filterBy, setFilterBy] = useState('');
	const theme = useTheme();
  const isDesktopOrLaptop = useMediaQuery(theme.breakpoints.up('sm'));
	const switchFiltering = useCallback((filter) => {
	setFilterBy(filter);
	}, [setFilterBy]);
	return (
		<div className="App">
			<Router>
			<ScrollToTop/>			
				<HeaderBar isNotMobile={isDesktopOrLaptop}/>
				<div className={classes.main}>	
			
				<Switch>
					<Route exact path="/">
						<img src={MainPhotos.Home} className={isDesktopOrLaptop ? classes.logo : classes.logoMobile} alt="Imagination Acres Farm Home"/>
						<HomeContent/>
					</Route>
					<Route exact path="/home">
						<img src={MainPhotos.Home} className={isDesktopOrLaptop ? classes.logo : classes.logoMobile} alt="Imagination Acres Farm Home"/>
						<HomeContent/>
					</Route>
					<Route path="/bucks">
						<BucksContent/>
						<GoatProfiles group={groups.buck} sales={false} isNotMobile={isDesktopOrLaptop}/>	
					</Route>
					<Route path="/does">
						<DoesContent/>
						<GoatProfiles group={groups.doe} sales={false} isNotMobile={isDesktopOrLaptop}/>
					</Route>
					<Route path="/reference">
						<>
						<ReferenceContent switchFiltering={switchFiltering} filterBy={filterBy}/>
						<GoatProfiles group={groups.reference} sales={false} filterBy={filterBy} isNotMobile={isDesktopOrLaptop}/>	
						</>
					</Route>
					<Route path="/bucksonice">
						<BucksOnIceContent/>
						<GoatProfiles group={groups.frozen} sales={false} isNotMobile={isDesktopOrLaptop}/>		
					</Route>
					<Route path="/goatsales">
						<SalesProfiles isNotMobile={isDesktopOrLaptop}/>
						<SalesPolicies/>
					</Route>
					<Route path="/rabbits">
						<RabbitsContent isNotMobile={isDesktopOrLaptop}/>
					</Route>
				</Switch>
				</div>
			</Router>
			<Footer/>
		</div>
	);
};

export default App;
