const Constants = {
	pages: {
		home: 'home',
		//bucks: 'bucks',
		//does: 'does',
		//bucksonice: 'bucksonice',
		reference: 'reference',
		goatsales: 'goatsales',
		rabbits: 'rabbits',	
	},
	pageMessages: {
		home: 'Home Page',
		//bucks: 'Goat Bucks',
		//does: 'Goat Does',
		//bucksonice: 'Goat Bucks On Ice',
		reference: 'Reference Goats',
		//goatsales: 'Goats for Sale',
		rabbits: 'Rabbits',	
	},
	pageArray: [
		'home',
		'rabbits',
		//'bucks',
		//'does',
		//'bucksonice',
		'reference',
		'goatsales',
	],
	groups: {
		buck: 'BUCK',
		doe: 'DOE',
		reference: 'REFERENCE',
		sales: 'SALES',
		frozen: 'FROZEN'
	}
};


export default Constants;
