import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TestResults from './Images/TestResults.js';

const useStyles = makeStyles((theme) => ({
	salesPolicies: {
		textAlign: 'start', 
		maxWidth: '1000px'
	},
	newLine: {
		whiteSpace: 'pre-line'
	},
	header: {
		textAlign: 'center',
		paddingBottom: '1rem'
	}
}));

export default function SalesPolicies() {
	const classes = useStyles();
	return(
	<>
		<div className={classes.header}>
			<Typography variant="h6">
			{'Goat Sales Policies:'}
			</Typography>			
		</div>
		<div className={classes.salesPolicies}>
			<br/>
			<Typography className={classes.newLine}>
			<li>For biosecurity reasons and due to Covid-19/RHDV2/Avian Influenza for the duration of 2023 we prefer that all animal pickups/sales will be done in a public place. Our farm is CLOSED to the public. If you elect to pickup from the farm the exchange of animals will take place in our driveway only.</li>
			<li>We will meet buyers for pickup in Spotsylvania, Virginia only, zipcode is 22551.</li>
			<li>Buyers may choose to use a transport company to pickup their goat but we will only work with licensed and insured transporters. Only weaned goats may be transported by a transport company. We will not send goats on transport that are bottle babies who are still on the bottle. (This includes most goats under 12 weeks of age) All goats on transport require a health certificate/CVI from our Veterinarian.</li>
			<li>Any goat sold directly from the farm to a buyer who lives outside the state of Virginia will require a health certificate from our Veterinarian (CVI).</li>
			<li>We reserve the right to refuse or cancel a sale to anyone, at any time, without reason. If a deposit has been placed and we as the sellers cancel the sale it will be refunded in full.</li>
			<li>A 50% non-refundable deposit is required to hold any goat until pickup if you are not picking the goat up within 24 hours (see below for more information on holding for pickup). 
				<ul>
					<li>Deposits must be made via Zelle.</li>
					<li>We accept cash or Zelle ONLY at the time of goat pickup.</li>
					<li>Goat prices are non-negotiable. Pickup will not be scheduled without a paid deposit.</li>
				</ul>
			</li>
			<li>After a deposit is made, pick up/delivery of the goat(s) must be arranged within 2 weeks. Only in extenuating circumstances will we hold a goat longer than 2 weeks and a boarding fee may be applied.
				<ul>
					<li> All goats are handle-able, though some may be friendlier than others. Some may be shy, nonetheless we cannot guarantee personality or temperament.</li>
				</ul>
			</li>
			<li>We do NOT sell goats under the age of 10 weeks unless they are bottle babies and going to a home familiar with the care a bottle baby entails.
				<ul>
					<li>No goats are sold as gifts or as pets for children, an adult must take full responsibility for the goat.</li>
					<li>We do not sell goats directly to anyone under the age of 18. If you are a youth you must have your parent/guardian contact us to handle the sale details. Proof of age may be requested.</li>
				</ul>
			</li>
			<li>ALL goats will come with transition feed and hay along with a care sheet and medical records. Bottle babies come with bottles and transition milk.</li>
			<li>Goats are sold in pairs only unless you already have goats. They are herd animals and need other goats for company.</li>
			<li>We cannot gurantee pregnancy or live kidding on does sold as exposed. Does sold as confirmed pregnant are confirmed to have tested positive for pregnancy through BioPryn blood test and/or ultrasound/X-rays for kid count - these results will be provided at the time of deposit/sale. Due to the stress of moving to new herds we cannot make any gurantees on the outcomes of these pregnancies. All exposed/bred does will come with ADGA breeding slips to register any kids born.</li>
			<li>All goats 6 months of age and older (at the time of our annual testing) have been tested for CAE and Johne’s. Please be aware kids for sale under this age have not been tested, but come from tested parents. Copies of test results are available below.</li>
			<li>ALL goats sold are up-to-date on vaccines (CD&T and Rabies only), deworming medication, external parasite treatment, coccidia prevention (kids only) and are disbudded/dehorned (horns removed). You will be provided with a copy of your goat’s vaccine and deworming records at pickup. All eligible goats will come with their ADGA registration papers or an application for registration.</li>
			</Typography>
			<br/>
			</div>
		<div className={classes.header}>
			<Typography variant="h6">
			{
				'2021 and 2022 CAE/Johne\'s Test Results'
			}
			</Typography>
			<span>
				<object data={TestResults.Test1} width="400px" height="500px">
					<Typography>{'Your browser doesn\'t support PDFs, if you\'re on a mobile device please view this page on a PC with a PDF supporting web browser to see our current test results.'}</Typography>
				</object>
				<object data={TestResults.Test2} width="400px" height="500px">
					<></>
				</object>
				<object data={TestResults.Test3} width="400px" height="500px">
					<></>
				</object>
			</span>
			<Typography>
			{
				'Note: All personal identifying information has been redacted from these results for privacy purposes.'
			}
			</Typography>
			<br/>
		</div>
		</>
	);
};
