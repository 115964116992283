import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	homeContent: {
		textAlign: 'center',
		whiteSpace: 'pre-line',
		maxWidth: '1000px'
	}
}));

export default function DoesContent() {
	const classes = useStyles();
	return(
		<div className={classes.homeContent}>
			<Typography>
			{'Listed here are the current does that make our farm home. Our does are listed in age order from oldest to youngest.'}
			</Typography>
		</div>
	);
};